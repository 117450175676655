import ReactGA from "react-ga4";
import { Component } from "react";

export default class AnalyticsService extends Component {
  static initialize(analyticsIdentifier) {
    ReactGA.initialize(analyticsIdentifier);
  }

  static sendPageView(pathname) {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }
}

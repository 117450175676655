import classes from './cartProductsIndicator.module.scss';

function CartProductsIndicator({ items }) {
  return (
    <div className={classes.indicator}>
      {items}
    </div>
  )
}

export default CartProductsIndicator;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./infobar.scss";

export default class InfoBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalIsVisible: true,
    };
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
  }

  toggleLoginModal() {
    this.setState({ loginModalIsVisible: !this.state.loginModalIsVisible });
  }

  render() {
    return (
      <div className="infobar">
        <ul className="infobar-links">
          <li>
            <a href="https://honvedfc.hu">honvedfc.hu</a>
          </li>
          <li className="active">
            <a href="https://shop.honvedfc.hu">Shop</a>
          </li>
          <li>
            <a href="https://jegy.honvedfc.hu">jegyek</a>
          </li>
          <li>
            <a href="http://bozsikarena.hu">Bozsik Aréna</a>
          </li>
          <li>
            <a href="http://magyarfutballakademia.hu">Akadémia</a>
          </li>
        </ul>

        <ul className="infobar-authentication">
          <li>
            <Link to="/regisztracio">Regisztráció</Link>
          </li>
          <li>
            <Link to="/bejelentkezes">Bejelentkezés</Link>
          </li>
        </ul>
      </div>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import CookieConsent from "../cookie/CookieConsent";

import "./footer.scss";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row footer-content">
          <div className="col-md-12 col-lg-3">
            <h5>Termékek</h5>
            <ul>
              <li>
                <Link to="/termekek/3">Macron</Link>
              </li>
              <li>
                <Link to="/termekek/4">Honvéd Collection</Link>
              </li>
              <li>
                <Link to="/termekek/5">Ajándéktárgyak</Link>
              </li>
              <li>
                <Link to="/termekek/6">Otthon</Link>
              </li>
              <li>
                <Link to="/termekek/10">Iskola</Link>
              </li>
              <li>
                <Link to="/termekek/7">Kiegészítők</Link>
              </li>
              <li>
                <Link to="/termekek/8">Szurkolói kellékek</Link>
              </li>
              <li>
                <Link to="/termekek/9">Akció</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-3">
            <h5>Információ</h5>
            <ul>
              <li>
                <Link to="/tartalom/aktualis">Aktuális</Link>
              </li>
              <li>
                <Link to="/tartalom/sugo">Súgó</Link>
              </li>
              <li>
                <Link to="/tartalom/merettablazat">Mérettáblázat</Link>
              </li>
              <li>
                <Link to="/tartalom/szallitas">Szállítás</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-3">
            <h5>Kapcsolat</h5>
            <p>
              Budapest Honvéd FC ajándékbolt
              <br />
              Üzemeltető:{" "}
              <a href="https://sportmerchandise.hu/" target="_blank" rel="noopener noreferrer">
                Sport Merchandise Hungary Kft.
              </a>
            </p>
            <p>
              Nyilvántartási szám: 01-09-358138
              <br />
              Adószám: 27940291-2-42
              <br />
              <br />
              E-mail: honvedshop@honvedfc.hu
            </p>
            <h5>Csomagjainkat kézbesíti</h5>
            <div className="delivery-partners-container">
              <img
                className="delivery-partner"
                src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/GLS_Logo_2021.png`}
                alt="GLS - Csomagszállítási partner"
              />
              <img
                className="delivery-partner"
                src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/mpl_logo.png`}
                alt="MPL - Csomagszállítási partner"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <h5>Kövess minket</h5>
            <div className="footer__social-container">
              <a
                className="footer__social"
                target="_blank"
                href="https://www.facebook.com/honvedfc/"
                rel="noreferrer noopener"
              >
                <img
                  src="https://depo.honvedfc.hu/web/images/social/icon-facebook.png"
                  alt="Facebook oldal"
                />
              </a>
              <a
                className="footer__social"
                target="_blank"
                href="https://www.youtube.com/user/budapesthonvedfc"
                rel="noreferrer noopener"
              >
                <img
                  src="https://depo.honvedfc.hu/web/images/social/icon-youtube.png"
                  alt="Youtube csatorna"
                />
              </a>
              <a
                className="footer__social"
                target="_blank"
                href="https://www.instagram.com/bphonvedfcofficial/?hl=hu"
                rel="noreferrer noopener"
              >
                <img
                  src="https://depo.honvedfc.hu/web/images/social/icon-instagram.png"
                  alt="Instagram"
                />
              </a>
              <a
                className="footer__social"
                target="_blank"
                href="https://www.linkedin.com/company/2962530"
                rel="noreferrer noopener"
              >
                <img
                  src="https://depo.honvedfc.hu/web/images/social/icon-linkedin.png"
                  alt="LinkedIn"
                />
              </a>
            </div>

            <h5>Fizetési szolgáltatónk</h5>
            <img
              className="payment-partner"
              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/barion.png`}
              alt="Barion"
            />

            <h5>Elfogadott fizetési rendszerek</h5>
            <img
              className="accepted-cards"
              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/barion-card-strip-intl.svg`}
              alt="Elfogadott bankkártyák"
            />
          </div>
        </div>
      </div>
      <div className="disclaimer">
        <div className="container disclaimer__content">
          <div className="disclaimer__copyright">
            &copy; 2003-2024
            <a href="http://www.thewebillustrated.com/" target="_blank" rel="noopener noreferrer">
              The Web Illustrated
            </a>
            , Honvéd FC Kft.
          </div>
          <div className="disclaimer__links">
            <Link to="/tartalom/adatvedelem">Adatvédelmi tájékoztató</Link>
            <Link to="/tartalom/aszf">ÁSZF</Link>
            <Link to="/tartalom/suti-szabalyzat">Süti szabályzat</Link>
          </div>
        </div>
      </div>
      <CookieConsent />
    </footer>
  );
}

export default Footer;

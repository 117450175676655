import React, { useEffect, useState, useCallback } from 'react';
import { API_BASE_URL } from 'config/config';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import '../parcel-points/parcel-points.scss';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 47.4730517,
  lng: 19.1019954,
};

const ParcelPointsMPL = (props) => {
  const [parcelPointsData, setParcelPointsData] = useState(null);
  const [, setMap] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem('parcelPointsMPLData')) {
      setParcelPointsData(JSON.parse(sessionStorage.getItem('parcelPointsMPLData')));
    } else {
      fetch(`${API_BASE_URL}/mpl-parcel-points`)
        .then((res) => res.json())
        .then(
          (json) => {
            console.log(json);
            setParcelPointsData(json.items);
            sessionStorage.setItem('parcelPointsMPLData', JSON.stringify(json.items));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setCenter(center);
    map.setZoom(11);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  const handleParcelPointSelection = (id) => {
    props.onSelected(id);
    handleCloseInfoWindow();
  };

  return (isLoaded && parcelPointsData && parcelPointsData.length) ? (
    <>
      <div style={{ visibility: 'hidden', height: 0 }}>{parcelPointsData.length}</div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer gridSize={60}>
          {(clusterer) => {
            return parcelPointsData.map((marker, index) => (
              <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} onClick={() => handleMarkerClick(marker)} clusterer={clusterer} />
            ))
          }
          }
        </MarkerClusterer>
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={handleCloseInfoWindow}
          >
            <div>
              <h4>{selectedMarker.name}</h4>
              <p>{selectedMarker.type === 'postamachine' ? 'Csomagautomata' : 'Csomagpont'}</p>
              <p>Cím: {selectedMarker.zipCode} {selectedMarker.city}, {selectedMarker.address}</p>
              <p>
                <button className="btn btn-primary btn-small" onClick={(e) => handleParcelPointSelection(selectedMarker.id)}>Kiválaszt</button>
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) : <div>Betöltés</div>
}

export default ParcelPointsMPL;

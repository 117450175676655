import React, { Component } from 'react';
import './order.scss';
import { Link } from 'react-router-dom';
import { API_BASE_URL, AUTH_BASE_URL } from '../../config/config';
import Header from '../shared/header/Header';
import Breadcrumb from '../shared/breadcrumb/Breadcrumb';
import Footer from '../shared/footer/Footer';
import AnalyticsService from '../../services/analytics';

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_success: null,
      order_number: null,
    };
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    this.checkLoginState();
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  checkLoginState() {
    fetch(`${AUTH_BASE_URL}/login/state`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.email) {
            this.setState({
              authorized: true,
            });
            this.storeOrder(json.id);
          } else {
            this.setState({
              authorized: false,
            });
          }
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  storeOrder(user_id) {
    fetch(`${API_BASE_URL}/order/store/${user_id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      body: JSON.stringify(sessionStorage.getItem('basket')),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.success) {
            this.setState({
              order_success: true,
              order_number: json.order_id,
            });
          } else {
            this.setState({
              order_success: false,
              order_number: null,
            });
          }
        },
        (error) => {
          this.setState({
            order_success: false,
            order_number: null,
          });
        },
      );
  }

  render() {
    return (
      <div>
        <Header />
        <Breadcrumb />
        {this.state.authorized === true && this.state.order_success === true && (
          <section className="main-content container order-container">
            <div className="col-12">
              <h2 className="h2">Rendelés</h2>
              <p className="text-center">
                Köszönjük a rendelésedet, melyet az alábbi azonosítóval véglegesítettünk:
                <strong>
                  #
                  {this.state.order_number}
                </strong>
                .
                <br />
                A visszaigazolást regisztrációkor megadott e-mail címedre elküldtük.
              </p>
              <p className="text-center"><Link to="/nyitolap" className="btn">tovább a nyitóoldalra</Link></p>
            </div>
          </section>
        )}

        {this.state.authorized === true && this.state.order_success === false && (
          <section className="main-content container order-container">
            <div className="col-12">
              <h2 className="h2">Rendelés</h2>
              <p className="text-center">Hiba történt a rendelés mentésekor!</p>
              <p className="text-center"><Link to="/nyitolap" className="btn">tovább a nyitóoldalra</Link></p>
            </div>
          </section>
        )}

        {this.state.authorized === null && (
          <section className="main-content container order-container">
            <div className="access-denied">
              <h2 className="h2">Rendelés</h2>
              <p className="product-lead text-center">Egy pillanat, és betöltjük a rendelés részleteit.</p>
            </div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";
import {
  AUTH_BASE_URL,
  STATIC_SERVER_URL,
  PRODUCT_SIZES,
  API_BASE_URL,
  BARION_POSKEY,
  BASE_HREF,
  PAYEE_EMAIL,
  BARION_API_URL,
} from "../../config/config";
import "./checkout.scss";
import Loader from "../../components/shared/loader/Loader";
import LoaderGray from "../../components/shared/loader/LoaderGray";
import _ from "lodash";
import ParcelPoints from '../../components/parcel-points/ParcelPoints';
import ParcelPointsData from '../../components/parcel-points-data/ParcelPointsData';
import AnalyticsService from "../../services/analytics";
import ParcelPointsMPL from "../../components/parcel-points-mpl/ParcelPointsMPL";

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      totalPrice: 0,
      user_id: null,
      order_success: null,
      unsupported_shipping_country: false,
      sideBarTop: 0,
      sideBarWidth: 0,
      registrationForm: {
        personal: {
          first_name: "",
          last_name: "",
          email: "",
          zip_code: "",
        },
        billing: {
          billing_name: "",
          billing_vat_number: "",
          billing_phone: "",
          billing_country: 0,
          billing_country_name: "",
          billing_zip: "",
          billing_city: "",
          billing_address: "",
        },
        shipping: {
          shipping_name: "",
          shipping_country: 0,
          shipping_country_name: "",
          shipping_zip: "",
          shipping_city: "",
          shipping_address: "",
        },
        general: {
          shipping_type: 1,
          shipping_price: 0,
          payment_type: 1,
          selected_parcel_point_id: null,
          selected_parcel_point_type: null,
          accept_terms: false,
          remark: "",
          basket: {},
        },
      },
      validation: {
        base: {
          last_name_error: "",
          first_name_error: "",
          password_error: "",
          zip_code_error: "",
        },
        billing: {
          name_error: "",
          phone_no_error: "",
          zip_code_error: "",
          address_error: "",
          vat_number_error: "",
        },
        shipping: {
          name_error: "",
          zip_code_error: "",
          address_error: "",
        },
      },
      countries: [],
      filtered_countries: [],
      authorized: null,
      shipping_change_success: null,
      billing_change_success: null,
      isShippingDataValid: null,
      isBillingDataValid: null,
      isBillingDataChanged: false,
      isShippingDataChanged: false,
      is_billing_loader_active: false,
      is_shipping_loader_active: false,
      is_loader_active: false,
    };
    this.getProductDetails = this.getProductDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
    this.handleBillingChange = this.handleBillingChange.bind(this);
    this.handleShippingChange = this.handleShippingChange.bind(this);
    this.handleGeneralChange = this.handleGeneralChange.bind(this);
    this.handleBillingSubmit = this.handleBillingSubmit.bind(this);
    this.handleShippingSubmit = this.handleShippingSubmit.bind(this);
    this.handleBillingCountryChange = this.handleBillingCountryChange.bind(this);
    this.handleShippingCountryChange = this.handleShippingCountryChange.bind(this);
    this.handleShippingMethodChange = this.handleShippingMethodChange.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.activateBillingCountrySearch = this.activateBillingCountrySearch.bind(this);
    this.activateShippingCountrySearch = this.activateShippingCountrySearch.bind(this);
    this.getBillingZipCode = this.getBillingZipCode.bind(this);
    this.getShippingZipCode = this.getShippingZipCode.bind(this);
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.handleParcelPointSelection = this.handleParcelPointSelection.bind(this);
    this.dispatchBarionPayment = this.dispatchBarionPayment.bind(this);
    this.isSticky = this.isSticky.bind(this);
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    if (sessionStorage.getItem("token")) {
      this.checkLoginState();
    } else {
      window.location.href = "/bejelentkezes/penztar";
    }

    // let products = JSON.parse(sessionStorage.getItem('basket'));

    /* if (products) {
            this.getProductDetails(products);
        } */
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isSticky);
  }

  checkLoginState() {
    fetch(`${AUTH_BASE_URL}/login/state`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.email) {
            const products = JSON.parse(sessionStorage.getItem("basket"));

            this.setState(
              (prevState) => ({
                authorized: true,
                user_id: json.id,
                basicRegistrationForm: {
                  first_name: json.first_name,
                  last_name: json.last_name,
                  email: json.email,
                  hash_id: json.hash_id,
                },
                country_name: "",
                registrationForm: {
                  personal: {
                    ...prevState.registrationForm.personal,
                  },
                  billing: {
                    ...prevState.registrationForm.billing,
                  },
                  shipping: {
                    ...prevState.registrationForm.shipping,
                  },
                  general: {
                    ...prevState.registrationForm.general,
                    basket: products,
                  },
                },
              }),
              () => {
                this.getCountryList(json.id);
                const sidebarEl = document
                  .querySelector(".order-details-box")
                  .getBoundingClientRect();
                this.setState({
                  sideBarTop: sidebarEl.top,
                  sideBarWidth: sidebarEl.width,
                });
                if (window.innerWidth >= 992) {
                  window.addEventListener("scroll", this.isSticky);
                }

                if (products) {
                  this.getProductDetails(products);
                }
              }
            );
          } else {
            this.setState({
              authorized: false,
            });
            // this.props.history.push("/bejelentkezes/penztar");
            window.location.href = "/bejelentkezes/penztar";
          }
        },
        (error) => { }
      );
  }

  isSticky() {
    if (document.querySelector(".order-details-box")) {
      const sidebarEl = document.querySelector(".order-details-box");
      const scrollTop = window.scrollY;
      if (scrollTop >= this.state.sideBarTop - 10) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }
    }
  }

  getCountryList(user_id) {
    fetch(`${AUTH_BASE_URL}/country-list`)
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState(
            {
              countries: json,
            },
            () => {
              this.getBillingData(user_id);
              this.getShippingData(user_id);
            }
          );
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getProductDetails(products) {
    let totalPrice = 0;
    products.forEach((item, index) => {
      fetch(`${API_BASE_URL}/product/${item.item_no}`)
        .then((res) => res.json())
        .then(
          (json) => {
            products[index].name = json.name;
            products[index].price = json.price;
            products[index].file_name = json.file_name;
            totalPrice += json.price * products[index].quantity;
            this.setState({
              products,
              totalPrice,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    });
  }

  handleOrderSubmit(event) {
    this.setState({
      is_loader_active: true,
    });

    fetch(`${API_BASE_URL}/order/store/${this.state.user_id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(this.state.registrationForm.general),
      // body: JSON.stringify(sessionStorage.getItem('basket'))
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.success) {
            if (Number(this.state.registrationForm.general.payment_type) === 3) {
              sessionStorage.setItem("order_id", json.order_id);
              this.dispatchBarionPayment(json.order_id);
            } else {
              this.setState({
                order_success: true,
                order_number: json.order_id,
                is_loader_active: false,
              });
            }
          } else {
            this.setState({
              order_success: false,
              order_number: null,
              is_loader_active: false,
            });
          }
        },
        (error) => {
          this.setState({
            order_success: false,
            order_number: null,
            is_loader_active: false,
          });
        }
      );
  }

  dispatchBarionPayment(order_id) {
    const paymentObj = {
      POSKey: BARION_POSKEY,
      PaymentType: "Immediate",
      FundingSources: ["All"],
      GuestCheckout: "True",
      Transactions: [
        {
          POSTransactionId: this.encodeOrderId(order_id),
          Payee: PAYEE_EMAIL,
          Total: this.state.totalPrice + this.state.registrationForm.general.shipping_price,
          Items: [],
        },
      ],
      RedirectUrl: `${BASE_HREF}/payment-callback`,
      CallbackUrl: `${API_BASE_URL}/change-payment-state`,
      Locale: "hu-HU",
      Currency: "HUF",
    };
    fetch(`${BARION_API_URL}/v2/Payment/Start`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentObj),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
          this.savePaymentIdToOrder(order_id, json.PaymentId);
          this.saveTransactionDetails(json);
        },
        (error) => {
          console.log(error);
          this.setState({
            order_success: false,
            order_number: null,
            is_loader_active: false,
          });
        }
      );
  }

  savePaymentIdToOrder(order_id, payment_id) {
    const paymentObj = {
      payment_id,
    };
    fetch(`${API_BASE_URL}/order/update/${btoa(order_id)}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(paymentObj),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveTransactionDetails(data) {
    const paymentObj = {
      PaymentId: data.PaymentId,
      Status: data.Status ? data.Status : "",
      POSTransactionId: data.POSTransactionId ? data.POSTransactionId : "",
      TransactionId: data.TransactionId ? data.TransactionId : "",
      Currency: data.Currency ? data.Currency : "",
    };
    fetch(`${API_BASE_URL}/transaction/store`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentObj),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
          window.location.href = data.GatewayUrl;
        },
        (error) => {
          console.log(error);
          window.location.href = data.GatewayUrl;
        }
      );
  }

  encodeOrderId(order_id) {
    return `EPAYT${btoa(order_id)}`;
  }

  handleChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
          [id]: isCheckbox ? isChecked : inputValue,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
    }));
  }

  handleTermsChange(event) {
    const inputValue = event.target.checked;

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
          accept_terms: inputValue,
        },
      },
    }));
  }

  handleBillingChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          [id]: isCheckbox ? isChecked : inputValue,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
      isBillingDataChanged: true,
      billing_change_success: null,
    }));
  }

  handleShippingChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          [id]: isCheckbox ? isChecked : inputValue,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
      isShippingDataChanged: true,
      shipping_change_success: null,
    }));
  }

  handleGeneralChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
          [id]: isCheckbox ? isChecked : inputValue,
        },
      },
    }));
  }

  getBillingData(id) {
    fetch(`${AUTH_BASE_URL}/profile/billing/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (Object.keys(json).length > 0) {
            json = json[0];
            this.setState((prevState) => ({
              authorized: true,
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  billing_name: json.billing_name,
                  billing_vat_number: json.billing_vat ? json.billing_vat : '',
                  billing_phone: json.phone_no,
                  billing_country: json.billing_country,
                  billing_country_name: json.billing_country_name,
                  billing_zip: json.billing_zip_code,
                  billing_city: json.billing_city,
                  billing_address: json.billing_address,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                },
                general: {
                  ...prevState.registrationForm.general,
                },
              }
            }), () => {
              if (this.validateBillingData()) {
                this.setState({
                  isBillingDataValid: true,
                });
              }
            })
          };
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getShippingData(id) {
    fetch(`${AUTH_BASE_URL}/profile/shipping/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (Object.keys(json).length > 0) {
            json = json[0];
            this.setState(
              (prevState) => ({
                authorized: true,
                registrationForm: {
                  personal: {
                    ...prevState.registrationForm.personal,
                  },
                  billing: {
                    ...prevState.registrationForm.billing,
                  },
                  shipping: {
                    shipping_name: json.shipping_name,
                    shipping_country: json.shipping_country,
                    shipping_country_name: json.shipping_country_name,
                    shipping_zip: json.shipping_zip_code,
                    shipping_city: json.shipping_city,
                    shipping_address: json.shipping_address,
                  },
                  general: {
                    ...prevState.registrationForm.general,
                  },
                },
              }),
              () => {
                this.getShippingPrice();
                if (this.validateShippingData()) {
                  this.setState({
                    isShippingDataValid: true,
                  });
                }
              }
            );
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getShippingPrice() {
    if (Number(this.state.registrationForm.general.shipping_type) === 2) {
      this.setState((prevState) => ({
        registrationForm: {
          personal: {
            ...prevState.registrationForm.personal,
          },
          billing: {
            ...prevState.registrationForm.billing,
          },
          shipping: {
            ...prevState.registrationForm.shipping,
          },
          general: {
            ...prevState.registrationForm.general,
            shipping_price: 0,
          },
        },
      }));
    } else {
      const shippingCountryName =
        this.state.registrationForm.shipping.shipping_country_name.toLowerCase();
      const shippingCountry = _.filter(this.state.countries, (o) =>
        o.name.toLowerCase().includes(shippingCountryName)
      );

      if (Number(this.state.registrationForm.general.shipping_type) === 1 && shippingCountry[0].zone_id === 7) {
        this.setState({
          unsupported_shipping_country: true,
        });
      } else {
        fetch(`${API_BASE_URL}/shipping-price/${this.state.registrationForm.general.shipping_type}/${shippingCountry[0].zone_id}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then(
            (json) => {
              this.setState((prevState) => ({
                authorized: true,
                registrationForm: {
                  personal: {
                    ...prevState.registrationForm.personal,
                  },
                  billing: {
                    ...prevState.registrationForm.billing,
                  },
                  shipping: {
                    ...prevState.registrationForm.shipping,
                  },
                  general: {
                    ...prevState.registrationForm.general,
                    shipping_price: parseInt(json[0].price),
                  },
                },
              }));
              /* if (this.validateShippingData()) {
                            this.setState({
                                isShippingDataValid: true
                            })
                        } */
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  handleBillingSubmit(event) {
    event.preventDefault();
    this.setState({
      is_billing_loader_active: true,
      active_billing_country_search: false,
    });

    if (this.validateBillingData() === true) {
      // if (true) {
      // console.log(this.state.registrationForm.billing);
      fetch(`${AUTH_BASE_URL}/profile/billing/${this.state.user_id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(this.state.registrationForm.billing),
      })
        .then((res) => res.json())
        .then(
          (json) => {
            if (json.success) {
              this.setState({
                billing_change_success: true,
                isBillingDataChanged: false,
                isBillingDataValid: true,
                is_billing_loader_active: false,
              });
            } else {
              this.setState({
                billing_change_success: false,
                isBillingDataValid: false,
                is_billing_loader_active: false,
              });
            }
          },
          (error) => {
            this.setState({
              billing_change_success: false,
              isBillingDataValid: false,
              is_billing_loader_active: false,
            });
          }
        );
    } else {
      this.setState({
        isBillingDataValid: false,
        isBillingDataChanged: false,
        is_billing_loader_active: false,
      });
    }
  }

  validateBillingData() {
    let isValid = true;

    if (
      !this.state.registrationForm.billing.billing_name ||
      this.state.registrationForm.billing.billing_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_name_error: "Helytelen számlázási név!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_name_error: "",
        },
      }));
    }

    const re = /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/;
    if (
      this.state.registrationForm.billing.billing_vat_number &&
      !re.test(String(this.state.registrationForm.billing.billing_vat_number).toLowerCase())
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_vat_number_error: "Helytelen adószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_vat_number_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_phone ||
      this.state.registrationForm.billing.billing_phone.length < 10
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_phone_no_error: "Helytelen telefonszám! Formátum: 06301112222",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_phone_no_error: "",
        },
      }));
    }

    const billingCountryName = this.state.registrationForm.billing.billing_country_name;
    if (!this.state.registrationForm.billing.billing_country) {
      const filteredArr = _.filter(this.state.countries, (o) =>
        o.name.toLowerCase().includes(billingCountryName.toLowerCase())
      );
      if (filteredArr.length !== 1) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            billing_country_error: "Helytelen országnév!",
          },
        }));
        isValid = false;
      } else {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            billing_country_error: "",
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_country_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_zip ||
      (this.state.registrationForm.billing.billing_country === 139 &&
        this.state.registrationForm.billing.billing_zip.toString().length !== 4)
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_zip_code_error: "Helytelen irányítószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_zip_code_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_address ||
      this.state.registrationForm.billing.billing_address.length <= 7
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_address_error: "Helytelen cím!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_address_error: "",
        },
      }));
    }

    return isValid;
  }

  validateShippingData() {
    let isValid = true;

    if (
      !this.state.registrationForm.shipping.shipping_name ||
      this.state.registrationForm.shipping.shipping_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_name_error: "Helytelen szállítási név!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_name_error: "",
        },
      }));
    }

    const shippingCountryName = this.state.registrationForm.shipping.shipping_country_name;
    if (!this.state.registrationForm.shipping.shipping_country) {
      const filteredArr = _.filter(this.state.countries, (o) =>
        o.name.toLowerCase().includes(shippingCountryName.toLowerCase())
      );
      if (filteredArr.length !== 1) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            shipping_country_error: "Helytelen országnév!",
          },
        }));
        isValid = false;
      } else {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            shipping_country_error: "",
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_country_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.shipping.shipping_zip ||
      (this.state.registrationForm.shipping.shipping_country === 139 &&
        this.state.registrationForm.shipping.shipping_zip.toString().length !== 4)
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_zip_code_error: "Helytelen irányítószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_zip_code_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.shipping.shipping_address ||
      this.state.registrationForm.shipping.shipping_address.length <= 7
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_address_error: "Helytelen szállítási cím!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_address_error: "",
        },
      }));
    }

    return isValid;
  }

  handleShippingSubmit(event) {
    event.preventDefault();
    this.setState({
      is_shipping_loader_active: true,
      active_shipping_country_search: false,
    });
    if (this.validateShippingData() === true) {
      fetch(`${AUTH_BASE_URL}/profile/shipping/${this.state.user_id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(this.state.registrationForm.shipping),
      })
        .then((res) => res.json())
        .then(
          (json) => {
            if (json.success) {
              this.setState(
                {
                  shipping_change_success: true,
                  isShippingDataChanged: false,
                  isShippingDataValid: true,
                  is_shipping_loader_active: false,
                },
                () => {
                  this.getShippingPrice();
                }
              );
            } else {
              this.setState({
                shipping_change_success: false,
                isShippingDataChanged: false,
                isShippingDataValid: false,
                is_shipping_loader_active: false,
              });
            }
          },
          (error) => {
            this.setState({
              shipping_change_success: false,
              isShippingDataChanged: false,
              isShippingDataValid: false,
              is_shipping_loader_active: false,
            });
          }
        );
    } else {
      this.setState({
        isShippingDataValid: false,
        isShippingDataChanged: false,
        is_shipping_loader_active: false,
      });
    }
  }

  handleShippingMethodChange(event) {
    const shippingType = event.target.value;

    this.setState(
      (prevState) => ({
        registrationForm: {
          personal: {
            ...prevState.registrationForm.personal,
          },
          billing: {
            ...prevState.registrationForm.billing,
          },
          shipping: {
            ...prevState.registrationForm.shipping,
          },
          general: {
            ...prevState.registrationForm.general,
            shipping_type: shippingType,
            selected_parcel_point_id: null,
            selected_parcel_point_type: null,  
          },
        },
      }),
      () => {
        this.getShippingPrice();
      }
    );
  }

  handlePaymentMethodChange(event) {
    const paymentType = event.target.value;

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
          payment_type: paymentType,
        },
      },
    }));
  }

  getBillingZipCode(event) {
    if (
      event.target.value.length === 4 &&
      this.state.registrationForm.billing.billing_country === 139
    ) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState((prevState) => ({
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                  billing_zip: json[0].zip,
                  billing_city: json[0].city,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                },
                general: {
                  ...prevState.registrationForm.general,
                },
              },
            }));
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  getShippingZipCode(event) {
    if (
      event.target.value.length === 4 &&
      this.state.registrationForm.shipping.shipping_country === 139
    ) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState((prevState) => ({
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                  shipping_zip: json[0].zip,
                  shipping_city: json[0].city,
                },
                general: {
                  ...prevState.registrationForm.general,
                },
              },
            }));
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  handleBillingCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) =>
      o.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const countryValue = event.target.value;
    let countryId = null;

    if (filteredArr.length === 1) {
      countryId = filteredArr[0].id;
    }

    this.setState((prevState) => ({
      filtered_countries: filteredArr,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          billing_country: countryId,
          billing_country_name: countryValue,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
    }));
  }

  handleShippingCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) =>
      o.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const countryValue = event.target.value;
    let countryId = null;

    if (filteredArr.length === 1) {
      countryId = filteredArr[0].id;
    }

    this.setState((prevState) => ({
      filtered_countries: filteredArr,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          shipping_country: countryId,
          shipping_country_name: countryValue,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
    }));
  }

  modifyBillingCountryValue(event, country_id, country_name) {
    this.setState((prevState) => ({
      active_billing_country_search: false,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          billing_country: country_id,
          billing_country_name: country_name,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
    }));
  }

  modifyShippingCountryValue(event, country_id, country_name) {
    this.setState((prevState) => ({
      active_shipping_country_search: false,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          shipping_country: country_id,
          shipping_country_name: country_name,
        },
        general: {
          ...prevState.registrationForm.general,
        },
      },
    }));
  }

  activateBillingCountrySearch() {
    this.setState({
      active_billing_country_search: true,
    });
  }

  activateShippingCountrySearch() {
    this.setState({
      active_shipping_country_search: true,
    });
  }

  handleParcelPointSelection(id) {
    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
        general: {
          ...prevState.registrationForm.general,
          selected_parcel_point_id: id,
          selected_parcel_point_type: Number(this.state.registrationForm.general.shipping_type),
        },
      },
    }), () => {
      const targetElement = document.getElementById('selected-parcel-point');
      if (targetElement) {
        const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - document.getElementsByClassName('navigation')[0].clientHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Header />
        <Breadcrumb />
        {this.state.authorized && this.state.order_success === null && (
          <section className="container checkout-container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="col-12">
                  <h2 className="h2">Pénztár</h2>
                </div>
                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Megrendelő adatai</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        Név: {this.state.basicRegistrationForm.last_name}
                        &nbsp;
                        {this.state.basicRegistrationForm.first_name}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        E-mail:
                        {this.state.basicRegistrationForm.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Számlázási adatok</h3>
                    </div>
                    <div className="col-12">
                      <form onSubmit={this.handleBillingSubmit}>
                        <div className="form-row">
                          <div className="form-group col-sm-12 col-lg-6">
                            <label htmlFor="billing_name">
                              Név <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billing_name"
                              value={this.state.registrationForm.billing.billing_name}
                              onChange={this.handleBillingChange}
                            />
                            <div className="validation_error">
                              {this.state.validation.billing_name_error}
                            </div>
                          </div>
                          <div className="form-group col-sm-12 col-lg-6">
                            <label htmlFor="billing_vat_number">
                              Adószám <small>(ÁFÁ-s számla igény esetén)</small>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billing_vat_number"
                              value={this.state.registrationForm.billing.billing_vat_number}
                              onChange={this.handleBillingChange}
                            />
                            <div className="validation_error">
                              {this.state.validation.billing_vat_number_error}
                            </div>
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="billing_phone">
                              Telefonszám <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billing_phone"
                              value={this.state.registrationForm.billing.billing_phone}
                              onChange={this.handleBillingChange}
                              placeholder="pl. 06301112222"
                            />
                            <div className="validation_error">
                              {this.state.validation.billing_phone_no_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="country_name">
                              Ország <span>*</span>
                            </label>
                            <input
                              type="hidden"
                              id="country_id"
                              value={this.state.registrationForm.billing.billing_country}
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="country_id"
                              value={this.state.registrationForm.billing.billing_country_name}
                              onChange={this.handleBillingCountryChange}
                              onFocus={this.activateBillingCountrySearch}
                              autoComplete="off"
                            />
                            {this.state.registrationForm.billing.billing_country_name.length > 2 &&
                              this.state.active_billing_country_search && (
                                <div className="predictive-country-list">
                                  {this.state.filtered_countries.map((item, index) => (
                                    <button
                                      type="button"
                                      key={item.id}
                                      onClick={(e) =>
                                        this.modifyBillingCountryValue(e, item.id, item.name)
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  ))}
                                </div>
                              )}
                            <div className="validation_error">
                              {this.state.validation.billing_country_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label htmlFor="billing_zip">
                              Irányítószám <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billing_zip"
                              autoComplete="chrome-off"
                              value={this.state.registrationForm.billing.billing_zip}
                              onKeyUp={this.getBillingZipCode}
                              onChange={this.handleBillingChange}
                            />
                            <div className="validation_error">
                              {this.state.validation.billing_zip_code_error}
                            </div>
                          </div>
                          <div className="form-group col-md-8">
                            <label htmlFor="billing_city">
                              Város <span>*</span>
                            </label>
                            <input
                              title=""
                              type="text"
                              className="form-control"
                              id="billing_city"
                              readOnly={this.state.registrationForm.billing.billing_country === 139}
                              value={this.state.registrationForm.billing.billing_city}
                              onChange={this.handleBillingChange}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label htmlFor="billing_address">
                              Cím <span>*</span>
                            </label>
                            <input
                              title=""
                              type="text"
                              className="form-control"
                              id="billing_address"
                              value={this.state.registrationForm.billing.billing_address}
                              onChange={this.handleBillingChange}
                            />
                            <div className="validation_error">
                              {this.state.validation.billing_address_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary btn-primary__small">
                              mentés
                            </button>
                            {this.state.is_billing_loader_active === true && <Loader />}
                            {this.state.isBillingDataChanged === true && (
                              <p className="save-modification">Mentsd el a módosításokat!</p>
                            )}
                            {this.state.billing_change_success === true && (
                              <p className="save-modification save-modification__success">
                                Sikeresen módosítottad a számlázási adatokat!
                              </p>
                            )}
                            {this.state.billing_change_success === false && (
                              <p className="save-modification">
                                Hiba a számlázási adatok módosításakor!
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Szállítási adatok</h3>
                    </div>
                    <div className="col-12">
                      <form onSubmit={this.handleShippingSubmit}>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="shipping_name">
                              Név <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipping_name"
                              value={this.state.registrationForm.shipping.shipping_name}
                              onChange={this.handleShippingChange}
                              disabled={
                                Number(this.state.registrationForm.general.shipping_type) === 4
                              }
                            />
                            <div className="validation_error">
                              {this.state.validation.shipping_name_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="country_name">
                              Ország
                              <span>*</span>
                            </label>
                            <input
                              type="hidden"
                              id="shipping_country_id"
                              value={this.state.registrationForm.shipping.shipping_country}
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="shipping_country_id"
                              value={this.state.registrationForm.shipping.shipping_country_name}
                              onChange={this.handleShippingCountryChange}
                              onFocus={this.activateShippingCountrySearch}
                              autoComplete="off"
                              disabled={
                                Number(this.state.registrationForm.general.shipping_type) === 4
                              }
                            />
                            {this.state.registrationForm.shipping.shipping_country_name.length >
                              2 &&
                              this.state.active_shipping_country_search && (
                                <div className="predictive-country-list">
                                  {this.state.filtered_countries.map((item, index) => (
                                    <button
                                      type="button"
                                      key={item.id}
                                      onClick={(e) =>
                                        this.modifyShippingCountryValue(e, item.id, item.name)
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  ))}
                                </div>
                              )}
                            <div className="validation_error">
                              {this.state.validation.shipping_country_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label htmlFor="shipping_zip">
                              Irányítószám <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipping_zip"
                              autoComplete="chrome-off"
                              value={this.state.registrationForm.shipping.shipping_zip}
                              onKeyUp={this.getShippingZipCode}
                              onChange={this.handleShippingChange}
                              disabled={
                                Number(this.state.registrationForm.general.shipping_type) === 4
                              }
                            />
                            <div className="validation_error">
                              {this.state.validation.shipping_zip_code_error}
                            </div>
                          </div>
                          <div className="form-group col-md-8">
                            <label htmlFor="shipping_city">
                              Város <span>*</span>
                            </label>
                            <input
                              title=""
                              type="text"
                              className="form-control"
                              id="shipping_city"
                              readOnly={
                                Number(this.state.registrationForm.shipping.shipping_country) ===
                                139
                              }
                              value={this.state.registrationForm.shipping.shipping_city}
                              onChange={this.handleShippingChange}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label htmlFor="shipping_address">
                              Cím <span>*</span>
                            </label>
                            <input
                              title=""
                              type="text"
                              className="form-control"
                              id="shipping_address"
                              value={this.state.registrationForm.shipping.shipping_address}
                              onChange={this.handleShippingChange}
                              disabled={
                                Number(this.state.registrationForm.general.shipping_type) === 4
                              }
                            />
                            <div className="validation_error">
                              {this.state.validation.shipping_address_error}
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary btn-primary__small" disabled={Number(this.state.registrationForm.general.shipping_type) === 4}>
                              mentés
                            </button>
                            {this.state.is_shipping_loader_active === true && <Loader />}
                            {this.state.isShippingDataChanged === true && (
                              <p className="save-modification">Mentsd el a módosításokat!</p>
                            )}
                            {this.state.shipping_change_success === true && (
                              <p className="save-modification save-modification__success">
                                Sikeresen módosítottad a szállítási adatokat!
                              </p>
                            )}
                            {this.state.shipping_change_success === false && (
                              <p className="save-modification">
                                Hiba a szállítási adatok módosításakor!
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Megjegyzés</h3>
                      <p>
                        Rendeléssel kapcsolatos megjegyzéseket itt várjuk (pl. mez feliratozás,
                        mobiltelefon pontos típusa tok esetében)
                      </p>
                      <div>
                        <textarea
                          className="form-control"
                          id="remark"
                          value={this.state.registrationForm.remark}
                          onChange={this.handleGeneralChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Szállítási mód</h3>
                      <div onChange={this.handleShippingMethodChange}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              Number(this.state.registrationForm.general.shipping_type) === 1
                            }
                            type="radio"
                            name="shipping_type"
                            id="shippingType1"
                            value="1"
                            onChange={() => { }}
                          />
                          <label className="form-check-label delivery" htmlFor="shippingType1">
                            GLS futárszolgálattal
                            <img
                              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/GLS_Logo_2021.png`}
                              alt="GLS - Csomagszállítási partner"
                            />
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              Number(this.state.registrationForm.general.shipping_type) === 4
                            }
                            type="radio"
                            name="shipping_type"
                            id="shippingType4"
                            value="4"
                            onChange={() => { }}
                            disabled={this.state.registrationForm.shipping.shipping_country !== 139}
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "GLS csomagpont/csomagautomata" : "Külföldi címre csak GLS házhozszállítás választható"}
                          />
                          <label
                            className="form-check-label delivery"
                            htmlFor="shippingType4"
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "GLS csomagpont/csomagautomata" : "Külföldi címre csak GLS házhozszállítás választható"}
                          >
                            GLS csomagpont/csomagautomata
                            <img
                              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/GLS_Csomagpont_Logo.png`}
                              alt="GLS - Csomagszállítási partner"
                            />
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              Number(this.state.registrationForm.general.shipping_type) === 3
                            }
                            type="radio"
                            name="shipping_type"
                            id="shippingType3"
                            value="3"
                            disabled={this.state.registrationForm.shipping.shipping_country !== 139}
                            onChange={() => { }}
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "Magyar Posta Logisztika házhozszállítás" : "Külföldi címre csak GLS-el szállítunk ki"}
                          />
                          <label
                            className="form-check-label delivery"
                            htmlFor="shippingType3"
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "Magyar Posta Logisztika házhozszállítás" : "Külföldi címre csak GLS-el szállítunk ki"}
                          >
                            MPL - házhozszállítás
                            <img
                              className="delivery-partner"
                              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/mpl_logo.png`}
                              alt="MPL - Csomagszállítási partner"
                            />
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              Number(this.state.registrationForm.general.shipping_type) === 5
                            }
                            type="radio"
                            name="shipping_type"
                            id="shippingType5"
                            value="5"
                            onChange={() => { }}
                            disabled={this.state.registrationForm.shipping.shipping_country !== 139}
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "MPL csomagpont/csomagautomata" : "Külföldi címre csak GLS házhozszállítás választható"}
                          />
                          <label
                            className="form-check-label delivery"
                            htmlFor="shippingType5"
                            title={this.state.registrationForm.shipping.shipping_country === 139 ? "MPL csomagpont/csomagautomata" : "Külföldi címre csak GLS házhozszállítás választható"}
                          >
                            MPL csomagpont/csomagautomata
                            <img
                              src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/MPL_Csomagpont_Logo.png`}
                              alt="MPL - Csomagszállítási partner"
                            />
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={
                              Number(this.state.registrationForm.general.shipping_type) === 2
                            }
                            type="radio"
                            name="shipping_type"
                            id="shippingType2"
                            value="2"
                            onChange={() => { }}
                          />
                          <label className="form-check-label" htmlFor="shippingType2">
                            Személyes átvétel
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {(Number(this.state.registrationForm.general.selected_parcel_point_id) > 0 && Number(this.state.registrationForm.general.selected_parcel_point_type) === 4) && (
                  <div className="box" id="selected-parcel-point">
                    <div className="row">
                      <div className="col-12 selected-parcel-point-details">
                        <ParcelPointsData
                          type={Number(this.state.registrationForm.general.shipping_type)}
                          data={JSON.parse(sessionStorage.getItem('parcelPointsData')).find(item => Number(item.externalId) === Number(this.state.registrationForm.general.selected_parcel_point_id))}
                        />
                        <figure>
                          <img
                            src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/GLS_Csomagpont_Logo.png`}
                            alt="GLS - Csomagszállítási partner"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                )
                }

                {(Number(this.state.registrationForm.general.selected_parcel_point_id) > 0 && Number(this.state.registrationForm.general.selected_parcel_point_type) === 5) && (
                  <div className="box" id="selected-parcel-point">
                    <div className="row">
                      <div className="col-12 selected-parcel-point-details">
                        <ParcelPointsData
                          type={Number(this.state.registrationForm.general.shipping_type)}
                          data={JSON.parse(sessionStorage.getItem('parcelPointsMPLData')).find(item => Number(item.id) === Number(this.state.registrationForm.general.selected_parcel_point_id))}
                        />
                        <figure>
                          <img src={`${process.env.REACT_APP_STATIC_SERVER_URL}/web/images/shop/MPL_Csomagpont_Logo.png`} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                )
                }

                {Number(this.state.registrationForm.general.shipping_type) === 4 && (
                  <div className="box">
                    <div className="row">
                      <div className="col-12">
                        <ParcelPoints onSelected={this.handleParcelPointSelection} />
                      </div>
                    </div>
                  </div>
                )}

                {Number(this.state.registrationForm.general.shipping_type) === 5 && (
                  <div className="box">
                    <div className="row">
                      <div className="col-12">
                        <ParcelPointsMPL onSelected={this.handleParcelPointSelection} />
                      </div>
                    </div>
                  </div>
                )}

                <div className="box">
                  <div className="row">
                    <div className="col-12">
                      <h3>Fizetési mód</h3>
                      <div onChange={this.handlePaymentMethodChange}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            checked={Number(this.state.registrationForm.general.payment_type) === 1}
                            type="radio"
                            name="payment_type"
                            id="paymentType1"
                            value="1"
                            onChange={() => { }}
                          />
                          <label className="form-check-label" htmlFor="paymentType1">
                            Banki átutalás
                          </label>
                        </div>
                        {this.state.registrationForm.shipping.shipping_country === 139 && (
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              checked={
                                Number(this.state.registrationForm.general.payment_type) === 2
                              }
                              type="radio"
                              name="payment_type"
                              id="paymentType2"
                              value="2"
                              onChange={() => { }}
                            />
                            <label className="form-check-label" htmlFor="paymentType2">
                              Kártyával vagy készpénzben a futárnál (utánvét)
                            </label>
                          </div>
                        )}
                        {this.state.registrationForm.shipping.shipping_country === 139 && (
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              checked={
                                Number(this.state.registrationForm.general.payment_type) === 3
                              }
                              type="radio"
                              name="payment_type"
                              id="paymentType3"
                              value="3"
                              onChange={() => { }}
                            />
                            <label className="form-check-label" htmlFor="paymentType3">
                              Online bankkártyás fizetés a Barion oldalán
                              <br />
                              <img
                                src={`${STATIC_SERVER_URL}/web/images/shop/payment/barion-card-strip-intl_200px.png`}
                                alt="Barion"
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4">
                <div className="order-details-container">
                  <div
                    className="order-details-box"
                    style={{ width: this.state.sideBarWidth ? this.state.sideBarWidth : "100%" }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="order-details">
                          <h3>Rendelésed részletei</h3>
                          {this.state.products.length !== 0 && (
                            <div>
                              <div className="order-details-table">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Termék</th>
                                      <th className="text-center">Méret</th>
                                      <th className="text-center">Mennyiség</th>
                                      <th>Ár</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.products.map((item, index) => (
                                      <tr key={item.item_no}>
                                        <td>{item.name}</td>
                                        {item.size !== null && (
                                          <td className="text-center">
                                            {`${PRODUCT_SIZES[item.size]}`}
                                          </td>
                                        )}
                                        {item.size === null && <td className="text-center">-</td>}
                                        <td className="text-center">{item.quantity}</td>
                                        <td>{(item.price * item.quantity).toLocaleString()} Ft</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <p>
                                Szállítás:{" "}
                                {this.state.registrationForm.general.shipping_price.toLocaleString()}{" "}
                                Ft
                              </p>
                              <h4>
                                Teljes összeg:{" "}
                                {(
                                  this.state.totalPrice +
                                  this.state.registrationForm.general.shipping_price
                                ).toLocaleString()}{" "}
                                Ft
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="accept_terms"
                            value={this.state.registrationForm.general.accept_terms}
                            onChange={this.handleTermsChange}
                          />
                          <label className="form-check-label" htmlFor="accept_terms">
                            Kijelentem, hogy az{" "}
                            <Link to="/tartalom/aszf" className="accept-terms-link">
                              Általános Szerződési Feltételeket
                            </Link>{" "}
                            megismertem és elfogadom.
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        {/* <Link className={(this.state.isBillingDataValid && this.state.isShippingDataValid) ? 'btn btn-primary btn-order-submit' : 'btn btn-primary btn-order-submit disabled'} to={`/rendeles`}>megrendelés véglegesítése</Link> */}
                        <button
                          type="submit"
                          className={
                            this.state.isBillingDataValid &&
                              this.state.isShippingDataValid &&
                              this.state.is_loader_active === false &&
                              ((Number(this.state.registrationForm.general.shipping_type) === 4 && this.state.registrationForm.general.selected_parcel_point_id) 
                              || (Number(this.state.registrationForm.general.shipping_type) === 5 && this.state.registrationForm.general.selected_parcel_point_id) 
                              || (Number(this.state.registrationForm.general.shipping_type) < 4)) 
                              && this.state.registrationForm.general.accept_terms === true
                              ? "btn btn-primary btn-order-submit"
                              : "btn btn-primary btn-order-submit disabled"
                          }
                          onClick={this.handleOrderSubmit}
                        >
                          megrendelés véglegesítése
                        </button>
                        {this.state.is_loader_active === true && <LoaderGray marginTop={12} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {this.state.authorized === null && (
          <section className="container">
            <div className="access-denied">
              <h2 className="h2">Pénztár</h2>
              <p className="product-lead text-center">
                Egy pillanat, és betöltjük a rendelés részleteit.
              </p>
            </div>
          </section>
        )}

        {this.state.authorized === true && this.state.order_success === true && (
          <section className="main-content container order-container">
            <div className="col-12">
              <h2 className="h2">Rendelés</h2>
              <p className="text-center">
                Köszönjük a rendelésedet, melyet az alábbi azonosítóval véglegesítettünk:{" "}
                <strong>#{this.state.order_number}</strong>
                .
                <br />A visszaigazolást regisztrációkor megadott e-mail címedre elküldtük.
              </p>
              <p className="text-center">
                <Link to="/nyitolap" className="btn">
                  tovább a nyitóoldalra
                </Link>
              </p>
            </div>
          </section>
        )}

        {this.state.authorized === true && this.state.order_success === false && (
          <section className="main-content container order-container">
            <div className="col-12">
              <h2 className="h2">Rendelés</h2>
              <p className="text-center">Hiba történt a rendelés mentésekor!</p>
              <p className="text-center">
                <Link to="/nyitolap" className="btn">
                  tovább a nyitóoldalra
                </Link>
              </p>
            </div>
          </section>
        )}

        {this.state.unsupported_shipping_country === true && (
          <section className="unsupported-shipping-country-modal-container">
            <div className="modal-content">
              <div>
                <p>
                  A kiválasztott országba egyedi ár alapján vállaljuk a szállítást.
                  <br />
                  További információért írj a{" "}
                  <a href="mailto:honvedshop@honvedfc.hu" className="text">
                    honvedshop@honvedfc.hu
                  </a>{" "}
                  címre.
                </p>
              </div>
              <div>
                <Link to="/nyitolap" className="red-btn">
                  tovább a nyitóoldalra
                </Link>
              </div>
            </div>
          </section>
        )}

        <Footer />
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";
import { useRecoilValue } from "recoil";
import AnalyticsService from "services/analytics";
import { searchValueState } from "atoms/atoms";
import Spinner from "components/shared/Spinner/Spinner";
import { API_BASE_URL } from "config/config";
import ProductListItem from "components/product/product-list/ProductListItem";

function Search() {
  const searchValue = useRecoilValue(searchValueState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AnalyticsService.sendPageView(window.location.pathname);

    const fetchData = () => {
      fetch(`${API_BASE_URL}/search/${searchValue}`)
        .then((res) => res.json())
        .then(
          (json) => {
            console.log(json);
            setProducts(json);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
          }
        );
    }

    if (searchValue && searchValue.length >= 3) {
      fetchData();
    } else {
      setProducts([]);
      setIsLoaded(true);
    }
  }, [searchValue]);

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div>
      <Header />
      <Breadcrumb />
      <section className="product-list-container">
        <h2 className="h2">Keresés</h2>
        <div className="row">
          {!searchValue.length && (
            <div className="col-12">
              <p className="text-center">Nem adtál meg keresési kulcsszót.</p>
            </div>
          )}
          {!!searchValue.length && searchValue.length >= 3 && !products.length && (
            <div className="col-12">
              <p className="text-center">Nincs találat!</p>
            </div>
          )}
          {!!searchValue.length && searchValue.length < 3 && (
            <div className="col-12">
              <p className="text-center">A kereséshez minimum három karaktert kell megadni!</p>
            </div>
          )}
          {products.map((item, key) => (
            <ProductListItem
              key={key}
              item_no={item.item_no}
              price={item.price}
              name={item.name}
              photo={item.file_name}
              id={item.item_no}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );

}

export default Search;

import classes from './spinner.module.scss';

function Spinner() {
  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader}></div>
    </div>
  )
}

export default Spinner;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Interweave } from "interweave";

import { BHO_API_BASE_URL, STATIC_SERVER_URL } from "../../config/config";
import Header from "../../components/shared/header/Header";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";
import Footer from "../../components/shared/footer/Footer";
import Spinner from "components/shared/Spinner/Spinner";

import AnalyticsService from "../../services/analytics";

import "./staticContent.scss";

function StaticContent() {
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AnalyticsService.sendPageView(window.location.pathname);

    const arrangeImagesSources = (text) => {
      const regex = new RegExp('<img class="img-fluid" src="', "g");
      const newText = text.replace(regex, `<img class="img-fluid" src="${STATIC_SERVER_URL}/web`);
      return newText;
    };

    const getItems = (id) => {
      fetch(`${BHO_API_BASE_URL}/static/view/${id}`)
        .then((res) => res.json())
        .then(
          (json) => {
            json[0].text = arrangeImagesSources(json[0].text);
            setItems(json);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            setError(true);
          }
        );
    };

    getItems(id);
  }, [id]);

  if (error) {
    return <div>Hiba történt!</div>;
  }

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div className="static-content__container">
      <Header />
      <Breadcrumb />
      <section className="container">
        <div className="row">
          {items.map((item) => (
            <div className="col-sm-12" key={item.id}>
              <h2 className="h2">{item.header}</h2>
              <Interweave
                className="static-content__text"
                content={item.text}
                allowList={[
                  "iframe",
                  "h1",
                  "h2",
                  "h3",
                  "h4",
                  "h5",
                  "p",
                  "strong",
                  "br",
                  "img",
                  "table",
                  "thead",
                  "tbody",
                  "tr",
                  "th",
                  "td",
                ]}
                tagName="div"
              />
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default StaticContent;

import React, { Component } from 'react';

export default class SizeSelect extends Component {
  render() {
    return (
      <div className={(this.props.isSelected) ? 'size-cube active' : 'size-cube'} onClick={this.props.onClick}>
        {this.props.sizeName}
      </div>
    );
  }
}

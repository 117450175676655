import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import Breadcrumb from '../../components/shared/breadcrumb/Breadcrumb';
import Footer from '../../components/shared/footer/Footer';
import { AUTH_BASE_URL } from '../../config/config';

import Header from '../../components/shared/header/Header';
import Loader from '../../components/shared/loader/Loader';
import AnalyticsService from '../../services/analytics';

import '../../components/auth/register/register.scss';
import { authorizedState } from 'atoms/atoms';

function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [authorized, setAuthorized] = useRecoilState(authorizedState);
  const [pageAfterRedirect, setPageAfterRedirect] = useState('nyitolap');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);

    fetch(`${AUTH_BASE_URL}/login/state`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setAuthorized((json.email) ? true : false);
        },
        (error) => setAuthorized(false),
      );

    if (id) setPageAfterRedirect(id);
  }, [id, setAuthorized]);

  const validate = () => {
    let isValid = true;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(formData?.email).toLowerCase())) {
      setError(prev => ({ ...prev, email_error: 'Helytelen e-mail cím' }));
      isValid = false;
    } else {
      setError(prev => ({ ...prev, email_error: '' }));
    }

    if (!formData?.password || formData?.password.length === 0) {
      setError(prev => ({ ...prev, password_error: 'A jelszó mező nem lehet üres!' }));
      isValid = false;
    } else {
      setError(prev => ({ ...prev, password_error: '' }));
    }

    return isValid;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate() === true) {
      setLoading(true);
      const requestBody = JSON.stringify(formData);

      fetch(`${AUTH_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: requestBody,
      })
        .then((res) => res.json())
        .then(
          (json) => {
            if (json.token) {
              sessionStorage.setItem('token', json.token);
              sessionStorage.setItem('provider', 'portal');
              setError(null);
              setLoading(false);
              setLoginSuccess(true);
              setTimeout(() => {
                setAuthorized(true);
                navigate(`/${pageAfterRedirect}`);
              }, 2000)
            } else {
              setLoading(false);
              setAuthorized(false);
              setLoginSuccess(false)
              setError(prev => ({ ...prev, login_error: 'Hibás e-mail cím és/vagy jelszó!' }));
            }
          },
          (error) => {
            setLoading(false);
            setAuthorized(true);
            setError(prev => ({ ...prev, login_error: 'Technikai hiba történt, kérjük próbáld meg a bejelentkezést később!' }));
          },
        );
    }
  }

  const handleChange = (event) => {
    const isCheckbox = event.target.type === 'checkbox';

    setError(prev => ({ ...prev, login_error: '' }));
    setFormData(prev => ({ ...prev, [event.target.name]: (isCheckbox ? event.target.checked : event.target.value) }));
  }

  return (
    <div className="fixtures-container">
      <Header />
      <Breadcrumb />

      {authorized && (
        <section className="main-content">
          <div className="container">
            <div className="access-denied">
              <h2 className="news-list-header">Be vagy jelentkezve</h2>
              <p className="text-center">Már beléptél a tagságoddal, nincs szükség újabb bejelentkezésre.</p>
              <p className="text-center"><Link to="/profil">tovább a profilomhoz &raquo;</Link></p>
            </div>
          </div>
        </section>
      )}
      {!authorized && (
        <section className="main-content register-background">
          <div className="container login-container">
            <h2 className="h2">Bejelentkezés</h2>
            <p className="text-center login-lead">
              Használhatod a Honvedfc.hu oldalon megadott adataidat is!
              <br />
              Ha gondod van a belépéssel, a Honvéd Shop-os felhasználónevedról a regisztrációt visszaigazoló e-mailünkben találsz információt, ha pedig elfelejtetted a jelszavadat, kattints ide az "eflelejtettem a jelszavam" hivatkozásra.
            </p>
            {/* <div className="social-container">
              <div>
                <FacebookLogin
                  appId="479443535523657"
                  autoLoad={true}
                  fields="name,email,picture"
                  cssClass="btn-custom-facebook"
                  callback={this.responseFacebook}
                />
              </div>
              <div>
                <GoogleLogin
                  clientId="484814130569-fbfd4v7abqsumklpfllgdi4c31u52mtd.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  className={'g-signin2'}
                />
              </div>
            </div>
            <div className="separator">
              <p>vagy</p>
              <span></span>
            </div> */}
            {loginSuccess && (
              <div className="login-success-message">
                Sikeresen bejelentkeztél, máris átirányítunk...
              </div>
            )}
            {error?.login_error && (
              <div className="login-error-message">
                {error.login_error}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="user-email">Email cím</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formData?.email || ''}
                  onChange={handleChange}
                />
                <div className="validation_error">{error?.email_error}</div>
              </div>
              <div className="form-group">
                <label htmlFor="user-password">Jelszó</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData?.password || ''}
                  onChange={handleChange}
                />
                <div className="validation_error">{error?.password_error}</div>
              </div>
              <div className="text-center submit-container">
                <button type="submit" className="btn btn-primary" disabled={(loading === true)}>Bejelentkezés</button>
                {loading && (
                  <Loader />
                )}
              </div>
            </form>
            <div className="text-center lost-password-container">
              <Link className="lost-password" to="/elfelejtett-jelszo">Elfelejtettem a jelszavam</Link>
            </div>
            <div className="text-center register-link-container">
              <p>
                Nincs még tagságod?
                <Link to="/regisztracio">Regisztrálj most!</Link>
              </p>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}

export default Login;

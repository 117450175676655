import React, { Component } from 'react';
import Breadcrumb from '../../shared/breadcrumb/Breadcrumb';
import Footer from '../../shared/footer/Footer';
import '../register/register.scss';
import { AUTH_BASE_URL } from '../../../config/config';
import { Link } from 'react-router-dom';
import Header from '../../shared/header/Header';
import Loader from '../../shared/loader/Loader';
import AnalyticsService from '../../../services/analytics';

export default class LostPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      email_error: '',
      is_loader_active: false,
      is_form_valid: false,
      update_success: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  validate() {
    let isValid = true;

    console.log(this.state.email);

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.email).toLowerCase())) {
      this.setState({ email_error: 'Helytelen e-mail cím!' });
      isValid = false;
    } else {
      this.setState({ email_error: '' });
      isValid = true;
    }

    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate() === true) {
      this.setState({
        is_loader_active: true,
      });

      fetch(`${AUTH_BASE_URL}/lost-password`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state),
      })
        .then((res) => res.json())
        .then(
          (json) => {
            if (json === true) {
              this.setState({
                update_success: true,
                is_loader_active: false,
              });
            }
          },
          (error) => {
            this.setState({
              update_success: false,
              is_loader_active: false,
            });
          },
        );
    } else {
      this.setState({
        update_success: false,
        is_loader_active: false,
      });
    }
  }

  handleChange(event) {
    const isCheckbox = event.target.type === 'checkbox';

    this.setState({
      [event.target.id]: isCheckbox
        ? event.target.checked
        : event.target.value,
    }, () => {
      if (this.validate() === true) {
        this.setState({
          is_form_valid: true,
        });
      } else {
        this.setState({
          is_form_valid: false,
        });
      }
    });
  }

  render() {
    return (
      <div className="fixtures-container">
        <Header />
        <Breadcrumb />

        {!this.state.authorized && (
          <section className="main-content register-background">
            <div className="container login-container">
              <h2 className="h2">Elfelejtett jelszó</h2>
              <p className="text-center login-lead">Add meg a regisztrációkor megadott e-mail címedet az új jelszó igényléséhez.</p>
              {this.state.update_success && (
                <div className="login-success-message">
                  Új jelszavadat sikeresen kiküldtük a megadott e-mail címre, ha a megadott címmel létezik regisztrációd.
                </div>
              )}
              {this.state.update_success === false && (
                <div className="login-error-message">
                  A jelszót technikai probléma miatt nem tudtuk kiküldeni, kérjük próbáld meg később.
                </div>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="user-email">Email cím</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <div className="validation_error">{this.state.email_error}</div>
                </div>
                <div className="text-center submit-container">
                  <button type="submit" className="btn btn-primary" disabled={(this.state.is_loader_active === true || this.state.is_form_valid === false)}>Jelszó küldése</button>
                  {this.state.is_loader_active === true && (
                    <Loader />
                  )}
                </div>
              </form>
              <div className="text-center register-link-container">
                <p>
                  Nincs még tagságod?
                  <Link to="/regisztracio">Regisztrálj most!</Link>
                </p>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import InfoBar from "../infobar/InfoBar";
import Navigation from "../navigation/Navigation";

import "./header.scss";

function Header() {
  const [scrollY, setScrollY] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setHeaderHeight(document.getElementsByTagName("header")[0].clientHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={scrollY > headerHeight ? "fixed" : ""}>
      <InfoBar />
      <Navigation />
    </header>
  );
}

export default Header;

import { atom } from "recoil";

export const authorizedState = atom({
  key: "authorizedState",
  default: false,
});

export const searchValueState = atom({
  key: "searchValueState",
  default: "",
});

export const cartState = atom({
  key: "cartState",
  default: [],
});

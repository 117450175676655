import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";
import ProductListItem from "../../components/product/product-list/ProductListItem";
import { API_BASE_URL } from "../../config/config";
import _ from "lodash";
import AnalyticsService from "../../services/analytics";
import Spinner from "components/shared/Spinner/Spinner";

import "./productlist.scss";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterValue, setFilterValue] = useState(1);
  const [navigationItem, setNavigation] = useState({
    label_hu: "Termékek",
  });
  /*const order = {
    order_by: "created_at",
    order_direction: "desc",
  };*/
  const orderTypes = [
    {
      id: 0,
      field: "visits",
      direction: "desc",
    },
    {
      id: 1,
      field: "created_at",
      direction: "desc",
    },
    {
      id: 2,
      field: "name",
      direction: "asc",
    },
    {
      id: 3,
      field: "price",
      direction: "asc",
    },
    {
      id: 4,
      field: "price",
      direction: "desc",
    },
  ];
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AnalyticsService.sendPageView(window.location.pathname);
    getItems(id);
    getNavigation(id);
  }, [id])

  const getItems = (id, fieldName = "created_at", direction = "desc") => {
    setIsLoaded(false);
    fetch(`${API_BASE_URL}/product-list/${id}?order_by=${fieldName}&order_direction=${direction}`)
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setProducts(json);
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
        }
      );
  }

  const changeItemOrdering = (event) => {
    const filterValue = parseInt(event.target.value);
    setFilterValue(filterValue);
    const filterObj = _.filter(orderTypes, (o) => o.id === filterValue);

    getItems(id, filterObj[0].field, filterObj[0].direction);
  }

  const getNavigation = (id) => {
    setIsLoaded(false);
    fetch(`${API_BASE_URL}/navigation/breadcrumb?page=${encodeURIComponent(`/termekek/${id}`)}`)
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setNavigation(json[2]);
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
        }
      );
  }

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div>
      <Header />
      <Breadcrumb />
      <section className="product-list-container">
        <h2 className="h2">{navigationItem.label_hu}</h2>
        <div className="row product-infobar">
          <div className="col-12">
            <div className="product-list-numbers">{products.length} termék található</div>
            <div className="filter-container">
              <select onChange={(e) => changeItemOrdering(e)} value={filterValue}>
                <option value="0">Népszerűség szerint</option>
                <option value="1">Legújabb elöl</option>
                <option value="2">Név szerint</option>
                <option value="3">Legolcsóbb elöl</option>
                <option value="4">Legdrágább elöl</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          {products.map((item, key) => (
            <ProductListItem
              key={key}
              item_no={item.item_no}
              price={item.price}
              name={item.name}
              photo={item.file_name}
              id={item.item_no}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ProductList;

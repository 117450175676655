import React, { Component } from "react";
import "./navigation-content.scss";
import { Link } from "react-router-dom";

export default class NavigationContent extends Component {
  constructor(props) {
    super(props);
    this.state = { activeClasses: [false] };
    this.addActiveClass = this.addActiveClass.bind(this);
  }

  addActiveClass(index) {
    const activeClasses = [];
    for (const i in this.state.activeClasses) {
      if (index === parseInt(i)) {
        activeClasses.push(!this.state.activeClasses[i]);
      } else {
        activeClasses.push(false);
      }
    }
    this.setState({ activeClasses });
  }

  render() {
    const { activeClasses } = this.state;

    return (
      <div className="navigation-content-container">
        <ul>
          <li>
            <button
              type="button"
              className={activeClasses[0] ? "active sub" : "sub"}
              onClick={() => this.addActiveClass(0)}
            >
              Termékek
            </button>
            <ul>
              <li>
                <Link to="/termekek/3">Macron</Link>
              </li>
              <li>
                <Link to="/termekek/4">Honvéd Collection</Link>
              </li>
              <li>
                <Link to="/termekek/5">Ajándéktárgyak</Link>
              </li>
              <li>
                <Link to="/termekek/6">Otthon</Link>
              </li>
              <li>
                <Link to="/termekek/10">Iskola</Link>
              </li>
              <li>
                <Link to="/termekek/7">Kiegészítők</Link>
              </li>
              <li>
                <Link to="/termekek/8">Szurkolói kellékek</Link>
              </li>
              <li>
                <Link to="/termekek/9">Akció</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/tartalom/aktualis">Aktuális</Link>
          </li>
          <li>
            <Link to="/tartalom/sugo">Súgó</Link>
          </li>
          <li>
            <Link to="/tartalom/shop-kapcsolat">Kapcsolat</Link>
          </li>
          <li className="show-mobile">
            <Link to="/kosar">Kosár</Link>
          </li>
          <li className="show-mobile">
            <Link to="/bejelentkezes">Bejelentkezés</Link>
          </li>
        </ul>
      </div>
    );
  }
}

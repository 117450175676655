function CartIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block w-6 h-6 "
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1 1h22v22H1z" />
        <g stroke="#1A1D21" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
          <path d="M4.713 9.177a.917.917 0 01.914-.844h12.746c.478 0 .875.367.914.844l.803 10.01a1.833 1.833 0 01-1.826 1.98H5.736a1.833 1.833 0 01-1.827-1.98l.803-10.01h.001zM15.667 8.333V6.5a3.667 3.667 0 10-7.334 0v1.833" />
        </g>
      </g>
    </svg>
  );
}

export default CartIcon

const ENVIRONMENT = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "develop";

export const BASE_HREF = `${window.document.location.protocol}//${window.document.location.host}`;

export const HOST_NAME = window.document.location.host;

export const API_BASE_URL =
  ENVIRONMENT === "production"
    ? "https://shop-prod-api.honvedfc.hu/api"
    : ENVIRONMENT === "staging"
    ? "https://shop-dev-api.honvedfc.hu/api"
    : "http://shop-dev.local/api";

export const AUTH_BASE_URL =
  ENVIRONMENT === "production"
    ? "https://auth-prod.honvedfc.hu/api"
    : ENVIRONMENT === "staging"
    ? "https://auth-test.honvedfc.hu/api"
    : "http://auth.honvedfc.local/api";

export const STATIC_SERVER_URL =
  ENVIRONMENT === "production"
    ? "https://depo.honvedfc.hu"
    : ENVIRONMENT === "staging"
    ? "https://depo.honvedfc.hu"
    : "http://depo.honvedfc.local";

export const BHO_API_BASE_URL =
  ENVIRONMENT === "production"
    ? "https://prod-api.honvedfc.hu/api"
    : ENVIRONMENT === "staging"
    ? "https://dev-api.honvedfc.hu/api"
    : "http://dev.honvedfc.local/api";

export const BARION_API_URL =
  ENVIRONMENT === "production"
    ? "https://api.barion.com"
    : ENVIRONMENT === "staging"
    ? "https://api.test.barion.com"
    : "http://api.barion.local";

export const BARION_PUBLIC_KEY =
  ENVIRONMENT === "production"
    ? "477e60029ff64a31a325987c014c7b19"
    : ENVIRONMENT === "staging"
    ? "6561959b71514d08a9d91c33d5efb903"
    : "6561959b71514d08a9d91c33d5efb903";

export const BARION_POSKEY =
  ENVIRONMENT === "production"
    ? "64e909d5171d489a8b3c0d6e35c3a8b4"
    : ENVIRONMENT === "staging"
    ? "3d8e5bd9bd1449bd9a5b4b7fd659073f"
    : "3d8e5bd9bd1449bd9a5b4b7fd659073f";

export const PAYEE_EMAIL = "info@sportmerchandise.hu";

export const PAYMENT_STATUS = {
  Prepared: "A fizetés folyamatban van.",
  Started: "A fizetés folyamatban van.",
  InProgress: "A fizetés folyamatban van.",
  Waiting: "A fizetés folyamatban van.",
  Reserved: "A fizetés folyamatban van.",
  Authorized: "A fizetés folyamatban van.",
  Canceled: "A fizetés sikertelen volt!",
  Succeeded: "A fizetés sikeresen megtörtént!",
  Failed: "A fizetés sikertelen volt!",
  Expired:
    "A fizetés sikertelen volt. Amennyiben az összeg zárolásra került, úgy visszatérítésre fog kerülni a kártyakibocsátó feltételei szerint.",
};

export const META_TAGS = {
  title: " - Budapest Honvéd FC",
};

export const DATE_LABELS = {
  rightNow: "éppen most",
  minutesBefore: "perccel ezelőtt",
  hoursBefore: "órája",
  daysBefore: "napja",
};

export const PRODUCT_SIZES = [
  "",
  "3XS",
  "2XS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
  "31-34",
  "35-38",
  "39-42",
  "43-46",
  "47-50",
];

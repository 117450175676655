import React, { Component } from "react";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import Breadcrumb from "../../components/shared/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import { API_BASE_URL, STATIC_SERVER_URL, PRODUCT_SIZES } from "../../config/config";
import AnalyticsService from "../../services/analytics";

import "./cart.scss";

export default class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoaded: false,
      totalPrice: 0,
    };
    this.removeItem = this.removeItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getBasketItems() {
    if (sessionStorage.getItem("basket") !== null) {
      // console.log(JSON.parse(sessionStorage.getItem('basket')));
      this.setState({
        products: JSON.parse(sessionStorage.getItem("basket")),
      });
    }
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    const products = JSON.parse(sessionStorage.getItem("basket"));

    if (products) {
      this.getProductDetails(products);
    }
  }

  /* componentDidUpdate() {
    if (sessionStorage.getItem('basket') !== null && !this.state.products) {
      AnalyticsService.sendPageView(window.location.pathname);
      let products = JSON.parse(sessionStorage.getItem('basket'));

      if (products) {
        this.getProductDetails(products);
      }
    }
  } */

  getProductDetails(products) {
    let totalPrice = 0;
    products.forEach((item, index) => {
      fetch(`${API_BASE_URL}/product/${item.item_no}`)
        .then((res) => res.json())
        .then(
          (json) => {
            products[index].name = json.name;
            products[index].price = json.price;
            products[index].file_name = json.file_name;
            totalPrice += json.price * products[index].quantity;

            this.setState({
              products,
              isLoaded: products.length === index + 1,
              totalPrice,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    });
  }

  removeItem(data, event) {
    const basket = JSON.parse(sessionStorage.getItem("basket"));

    basket.splice(data, 1);
    sessionStorage.setItem("basket", JSON.stringify(basket));
    this.setState({
      products: JSON.parse(sessionStorage.getItem("basket")),
    });
    this.getProductDetails(basket);
  }

  handleChange(index, quantity, event) {
    const productData = this.state.products;
    productData[index].quantity = event.target.value;

    this.setState(
      {
        products: productData,
      },
      () => {
        this.getProductDetails(productData);
      }
    );
  }

  render() {
    return (
      <div>
        <Header />
        <Breadcrumb />
        <section className="main-content basket-details__container container">
          <div className="row">
            <div className="col-12">
              <h2 className="h2">Kosár</h2>
            </div>

            {!!this.state.products.length && (
              <div className="col-sm-12 col-lg-8">
                <div>
                  <table className="table basket-details__table show-desktop">
                    <thead>
                      <tr>
                        <th colSpan="2">Termék</th>
                        <th>Mennyiség</th>
                        <th>Ár</th>
                        <th>Összesen</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={`${STATIC_SERVER_URL}/web/images/shop/products/${item.item_no}_001.png`}
                              alt={item.name}
                            />
                          </td>
                          <td className="product-detail">
                            <p>
                              <strong>{item.name}</strong>
                            </p>
                            {item.size !== null && (
                              <p>
                                Méret:
                                <input
                                  className="form-control"
                                  type="text"
                                  readOnly
                                  value={PRODUCT_SIZES[item.size]}
                                />
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="number"
                              value={item.quantity}
                              readOnly
                              onChange={this.handleChange.bind(null, index, item.quantity)}
                            />
                          </td>
                          <td>{!isNaN(item.price) ? item.price.toLocaleString() : ""} Ft</td>
                          <td>{(item.price * item.quantity).toLocaleString()} Ft</td>
                          <td>
                            <button
                              className="btn-remove-basket-item"
                              title="Törlés"
                              onClick={this.removeItem.bind(null, index)}
                            >
                              <svg
                                fill="none"
                                viewBox="0 0 14 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m13.5 2.5h-2.5938l-1.06245-1.75c-.25-.40625-.8125-.75-1.28125-.75h-3.15625c-.46875 0-1.03125.34375-1.28125.75l-1.0625 1.75h-2.5625c-.28125 0-.5.25-.5.5v.5c0 .28125.21875.5.5.5h.5l.65625 10.5938c.03125.7812.71875 1.4062 1.5 1.4062h7.65625c.7813 0 1.4687-.625 1.5-1.4062l.6875-10.5938h.5c.25 0 .5-.21875.5-.5v-.5c0-.25-.25-.5-.5-.5zm-8.09375-1h3.15625l.59375 1h-4.34375zm5.40625 13h-7.65625l-.65625-10.5h8.9688z"
                                  fill="#e36464"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="show-mobile basket-details__mobile">
                    {this.state.products.map((item, index) => (
                      <div key={index} className="row basket-item">
                        <div className="col-12">
                          <img
                            className="img-fluid"
                            src={`${STATIC_SERVER_URL}/web/images/shop/products/${item.item_no}_001.png`}
                            alt={item.name}
                          />
                        </div>
                        <div className="col-12">
                          <p className="text-center">
                            <strong>{item.name}</strong>
                          </p>
                        </div>
                        <div className="col-12">
                          <p className="text-center price">
                            {(item.price * item.quantity).toLocaleString()} Ft
                          </p>
                        </div>
                        <div className="col-6">
                          {item.size !== null && (
                            <p>
                              Méret:
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={PRODUCT_SIZES[item.size]}
                              />
                            </p>
                          )}
                        </div>
                        <div className="col-6">
                          <p>
                            Darab:
                            <input
                              className="form-control"
                              type="number"
                              value={item.quantity}
                              onChange={this.handleChange.bind(null, index, item.quantity)}
                            />
                          </p>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-secondary"
                            onClick={this.removeItem.bind(null, index)}
                          >
                            Törlés
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.state.products.length !== 0 && (
              <div className="col-sm-12 col-lg-4">
                <div className="checkout-box">
                  <h3>Összegzés</h3>
                  <h4>
                    Rendelés összértéke
                    <span>{this.state.totalPrice.toLocaleString()} Ft</span>
                  </h4>
                  <div className="col-12">
                    <Link className="btn btn-primary" to="/penztar">
                      megrendelés
                    </Link>
                  </div>
                  <div className="col-12">
                    <Link className="btn btn-secondary" to="/">
                      tovább vásárolok
                    </Link>
                  </div>
                  <div className="col-12">
                    <p>
                      A megrendelés oldalon számlázási és szállítási adataidat adhatod meg és az
                      áruátvétel módját választhatod ki.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {this.state.products.length === 0 && (
              <div className="col-12">
                <p className="text-center">A kosaradban nincsenek termékek.</p>
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigationContent from "../navigation/NavigationContent";
import { STATIC_SERVER_URL } from "../../../config/config";

import "./hamburger.scss";

export default class Hamburger extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleNavigationChange = this.handleNavigationChange.bind(this);
    this._isMounted = false;
  }

  handleClick(e) {
    e.preventDefault();
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  handleScroll() {
    this.setState((state) => ({
      scroll: window.scrollY,
      isToggleOn: false,
    }));
  }

  componentDidMount() {
    this._isMounted = true;
    const el = document.getElementById("hamburger-navigation");
    if (this._isMounted) {
      this.setState({ top: el.offsetTop, height: el.offsetHeight });
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentDidUpdate() {
    /*this.state.scroll > this.state.top
      ? document.body.style.paddingTop = `${this.state.height}px`
      : document.body.style.paddingTop = 0;*/
  }

  componentWillUnmount() {
    document.body.style.paddingTop = 0;
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  handleNavigationChange() {
    this.setState({
      isToggleOn: false,
    });
  }

  render() {
    return (
      <nav
        id="hamburger-navigation"
        className={this.state.scroll > this.state.top ? "fixed-nav" : ""}
      >
        <div className="honvedfc-logo">
          <Link to="/nyitolap">
            <img
              src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
              alt="kezdőlap"
              title="kezdőlap"
            />
          </Link>
          <div className="title">
            <h1>Honvéd Shop</h1>
          </div>
        </div>
        <button
          type="button"
          className={`menu-toggle ${this.state.isToggleOn ? "active" : ""}`}
          onClick={this.handleClick}
        >
          Menü
        </button>
        <div className={`menu-sub ${this.state.isToggleOn ? "show" : "hidden"}`}>
          <div className="navigation-headline">
            <div className="honvedfc-logo">
              <Link to="/nyitolap">
                <img
                  src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
                  alt="kezdőlap"
                  title="kezdőlap"
                />
              </Link>
            </div>
            <div className="title">
              <h1>Menü</h1>
            </div>
          </div>
          <NavigationContent onNavigationClicked={this.handleNavigationChange} />
        </div>
      </nav>
    );
  }
}

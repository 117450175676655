function ProfileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block w-6 h-6 "
    >
      <g fill="none" fillRule="evenodd">
        <path d="M2 2h20v20H2z" />
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(2.833 2.833)"
          stroke="#1A1D21"
          strokeWidth="1.2"
        >
          <path d="M15 16.238v-.62a2.5 2.5 0 00-1.26-2.171l-2.683-1.535M7.272 11.91l-2.679 1.537a2.5 2.5 0 00-1.26 2.17v.621" />
          <path d="M9.167 12.5h0a3.333 3.333 0 01-3.334-3.333V7.5a3.333 3.333 0 013.334-3.333h0A3.333 3.333 0 0112.5 7.5v1.667A3.333 3.333 0 019.167 12.5z" />
          <circle cx="9.167" cy="9.167" r="9.167" />
        </g>
      </g>
    </svg>
  );
}

export default ProfileIcon;

import React, { Component } from "react";
// import Hamburger from "../../shared/Hamburger";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import Footer from "../../shared/footer/Footer";
import "../register/register.scss";
import { AUTH_BASE_URL } from "../../../config/config";
import { Link } from "react-router-dom";
import Header from "../../shared/header/Header";
import Loader from "../../shared/loader/Loader";
import AnalyticsService from "../../../services/analytics";

export default class ValidateCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKeyValid: true,
      sign: window.location.pathname.split("/")[2],
      validationCode: null,
      isFinalized: false,
      is_loader_active: false,
    };
    this.validateKey = this.validateKey.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateKey();
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  sendPageView() {
    window.ga("set", "page", document.location.pathname);
    window.ga("send", "pageview");
  }

  validateKey() {
    const parts = window.location.pathname.split("/");
    const id = parts[parts.length - 1];

    fetch(`${AUTH_BASE_URL}/validate-key/${id}`)
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
          this.setState({
            isKeyValid: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleChange(event) {
    const isCheckbox = event.target.type === "checkbox";

    this.setState({
      [event.target.id]: isCheckbox ? event.target.checked : event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(event);
    this.setState({
      is_loader_active: true,
    });

    fetch(`${AUTH_BASE_URL}/finalize-registration`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
          this.setState({
            isFinalized: json,
            is_loader_active: false,
          });
          /* this.props.history.push('/validate-code/' + json['sign']); */
        },
        (error) => {
          // console.log(error);
          this.setState({
            is_loader_active: false,
          });
        }
      );
  }

  render() {
    const { isKeyValid, isFinalized } = this.state;

    return (
      <div className="fixtures-container">
        <Header />
        <Breadcrumb />

        {isKeyValid && !isFinalized && (
          <section className="main-content register-background">
            <div className="container club-register-container">
              <h2 className="h2">Már csak egy lépés</h2>
              <p className="text-center">
                A regisztrációd érvényesítéséhez add meg az e-mail-ben kapott hat számjegyű kódot.
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6 offset-3 text-center">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCode"
                      disabled={isFinalized}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.is_loader_active === true}
                  >
                    Érvényesítem
                  </button>
                  {this.state.is_loader_active === true && <Loader />}
                </div>
              </form>
            </div>
          </section>
        )}
        {isKeyValid && isFinalized && (
          <section className="main-content register-background">
            <div className="container club-register-container">
              <h2 className="h2">Végeztünk!</h2>
              <p className="text-center">
                A regisztrációdat aktiváltad, innentől minden extra funkciót és tartalmat elérsz.
              </p>
              <div className="text-center">
                <Link className="btn btn-primary" to="/nyitolap">
                  Tovább a nyitólapra
                </Link>
              </div>
            </div>
          </section>
        )}
        {!isKeyValid && (
          <section className="main-content">
            <div className="container club-register-container">
              <h2 className="h2">Hiba</h2>
              <p>Nem aktiválható a felhasználói fiók, vagy a kód érvénytelen!</p>
            </div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}

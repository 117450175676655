import { useState } from "react";
import './parcel-points-data.scss';

const ParcelPointsData = (props) => {
  console.log(props);
  const [parcelPointData,] = useState({
    type: props.type === 4 ? (props.data.type === 'parcel-locker' ? 'Csomagautomata' : 'Csomagpont') : (props.data.type === 'postamachine' ? 'Csomagautomata' : 'Csomagpont'),
    name: props.data.name,
    zip: props.type === 4 ? props.data.contact.postalCode : props.data.zipCode,
    address: props.type === 4 ? `${props.data.contact.city}, ${props.data.contact.address}` : props.data.address,
  });

  return (
    <div className="selected-parcel-point-data">
      <h3>Kiválasztott átvételi hely</h3>
      <p>{parcelPointData.name} <strong>({parcelPointData.type})</strong></p>
      <p>Cím: {parcelPointData.zip} {parcelPointData.address}</p>
    </div>
  );
}

export default ParcelPointsData;

import React, { useEffect, useState } from "react";
import ProductListItem from "../../product/product-list/ProductListItem";
import { API_BASE_URL } from "../../../config/config";
import Spinner from "components/shared/Spinner/Spinner";

function PopularProducts(props) {
  const [productData, setProductData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/popular-products/4`)
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setProductData(json);
        },
        (error) => {
          setIsLoaded(true);
        }
      );
  }, []);

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div className="promobox-secondary-container">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center">Népszerű termékek</h2>
        </div>
      </div>
      <div className="row">
        {productData.map((item, key) => (
          <ProductListItem
            key={key}
            item_no={item.item_no}
            price={item.price}
            name={item.name}
            id={item.item_no}
          />
        ))}
      </div>
    </div>
  );
}

export default PopularProducts;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.scss';
import { API_BASE_URL } from '../../../config/config';

export default class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.getItems(this.transformPathname());
  }

  getItems(id) {
    fetch(`${API_BASE_URL}/navigation/breadcrumb?page=${encodeURIComponent(id)}`)
      .then((res) => res.json())
      .then(
        (json) => {
          const data = this.transformId(json);
          this.setState({
            isLoaded: true,
            items: data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  transformId(data) {
    const urlArr = window.location.pathname.split('/');
    if (urlArr[1] === 'jatekos-adatlap') {
      const queryArr = document.location.search.replace('?', '').split('=');
      if (queryArr[0] === 'squad') {
        queryArr[1] = (parseInt(queryArr[1]) === 1 || parseInt(queryArr[1]) === 3) ? queryArr[1] : 1;
        data[1].link_hu = data[1].link_hu.replace(':id', queryArr[1]);
        data[1].link_en = data[1].link_en.replace(':id', queryArr[1]);
      }
    }

    return data;
  }

  transformPathname() {
    const urlArr = window.location.pathname.split('/');
    /* if (urlArr.length === 3 && urlArr[1] !== 'hirek-listazas') {
            urlArr[2] = ":id";
        } */
    if (urlArr.length === 3 && urlArr[1] === 'tartalom') {
      urlArr[2] = ':id';
    }
    if (urlArr.length === 3 && urlArr[1] === 'termek') {
      urlArr[2] = ':id';
    }
    if (urlArr[1] === 'validate-code') {
      urlArr.pop();
    }

    /* if (urlArr.length === 4 && urlArr[1] === 'hirek') {
            urlArr[2] = ":slug";
            urlArr[3] = ":uploaded";
        } */
    return urlArr.join('/');
  }

  render() {
    const { items } = this.state;

    return (
      <div className="breadcrumb-container">
        <div className="container">
          <div>
            <ul>
              {items.map((item) => (
                <li key={item.id} className="breadcrumb__item">
                  {item.clickable === 1 && (
                    <Link to={item.link_hu}>{item.label_hu}</Link>
                  )}
                  {item.clickable === 0 && (
                    <button type="button" className="non-clickable">{item.label_hu}</button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import './cookieConsent.scss';
import { Link } from 'react-router-dom';
import { HOST_NAME } from '../../../config/config';

export default class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: false,
    };
    this.acceptCookie = this.acceptCookie.bind(this);
  }

  componentDidMount() {
    document.cookie.split(';').forEach((value, index) => {
      this.setState({ accepted: true });
      if (value.trim() === 'acceptedCookies=true') {
        this.setState({ accepted: true });
      }
    });
  }

  acceptCookie(event) {
    this.setState({ accepted: true });
    const cookieName = 'acceptedCookies';
    const cookieValue = 'true';
    const myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 12);
    document.cookie = `${cookieName}=${cookieValue};expires=${myDate
    };domain=.${HOST_NAME};path=/`;
  }

  render() {
    return (
      <div>
        <section className={(this.state.accepted === true) ? 'cookie-accept hidden' : 'cookie-accept'}>
          <p>Mi – és partnereink – cookie-kat használunk a szolgáltatásaink nyújtásához, és az érdeklődési körödnek megfelelő hirdetések megjelenítéséhez. A webhelyünk használatával elfogadod a cookie-k Cookie-szabályzatunk szerinti használatát.</p>
          <p className="action">
            <button onClick={this.acceptCookie}>Elfogadom</button>
            <Link to="/tartalom/suti-szabalyzat">részletes információ &raquo;</Link>
          </p>
        </section>
      </div>
    );
  }
}

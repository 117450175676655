import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL, STATIC_SERVER_URL } from "../../../config/config";

import "./hero.scss";

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      image_desktop: "",
      image_mobile: "",
      title: "",
    };
    this.getBanner = this.getBanner.bind(this);
  }

  componentDidMount() {
    this.getBanner();
  }

  getBanner() {
    fetch(`${API_BASE_URL}/banner/show`)
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            url: json.url,
            image_desktop: json.image_desktop,
            image_mobile: json.image_mobile,
            title: json.title,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  getImageFileName(desktopImage, mobileImage) {
    return `${STATIC_SERVER_URL}/web/images/shop/${
      window.innerWidth < 768 ? mobileImage : desktopImage
    }`;
  }

  render() {
    return (
      <div className="hero-container">
        <Link to={this.state.url}>
          <img
            src={this.getImageFileName(this.state.image_desktop, this.state.image_mobile)}
            alt={this.state.title}
          />
        </Link>
      </div>
    );
  }
}

import React, { Component } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import Footer from "../../shared/footer/Footer";
import "../register/register.scss";
import { AUTH_BASE_URL } from "../../../config/config";
import _ from "lodash";
import Header from "../../shared/header/Header";
import Loader from "../../shared/loader/Loader";
import { Link } from "react-router-dom";
import AnalyticsService from "../../../services/analytics";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationForm: {
        personal: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_repeat: "",
          zip_code: "",
          living_abroad: false,
          country_id: "",
          country_name: "",
          accept_terms: false,
          hash_id: "",
        },
        billing: {
          billing_name: "",
          billing_vat_number: "",
          billing_phone: "",
          billing_country: "",
          billing_country_name: "",
          billing_zip: "",
          billing_city: "",
          billing_address: "",
        },
        shipping: {
          shipping_name: "",
          shipping_country: "",
          shipping_country_name: "",
          shipping_zip: "",
          shipping_city: "",
          shipping_address: "",
        },
      },
      validation: {
        base: {
          last_name_error: "",
          first_name_error: "",
          password_error: "",
          zip_code_error: "",
        },
        billing: {
          name_error: "",
          phone_no_error: "",
          billing_country_error: "",
          zip_code_error: "",
          city_error: "",
          address_error: "",
          vat_number_error: "",
        },
        shipping: {
          name_error: "",
          zip_code_error: "",
          city_error: "",
          address_error: "",
        },
      },
      countries: [],
      filtered_countries: [],
      active_country_search: false,
      active_billing_country_search: false,
      active_shipping_country_search: false,
      authorized: null,
      profile_change_success: null,
      is_registration_form_valid: null,
      is_loader_active: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBillingChange = this.handleBillingChange.bind(this);
    this.handleShippingChange = this.handleShippingChange.bind(this);
    this.handleBillingCountryChange = this.handleBillingCountryChange.bind(this);
    this.handleShippingCountryChange = this.handleShippingCountryChange.bind(this);
    this.modifyCountryValue = this.modifyCountryValue.bind(this);
    this.modifyBillingCountryValue = this.modifyBillingCountryValue.bind(this);
    this.modifyShippingCountryValue = this.modifyShippingCountryValue.bind(this);
    this.activateCountrySearch = this.activateCountrySearch.bind(this);
    this.activateBillingCountrySearch = this.activateBillingCountrySearch.bind(this);
    this.activateShippingCountrySearch = this.activateShippingCountrySearch.bind(this);
    this.getZipCode = this.getZipCode.bind(this);
    this.getBillingZipCode = this.getBillingZipCode.bind(this);
    this.getShippingZipCode = this.getShippingZipCode.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    this.checkLoginState();
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  checkLoginState() {
    fetch(`${AUTH_BASE_URL}/login/state`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.email) {
            this.setState({
              authorized: true,
              registrationForm: {
                personal: {
                  first_name: json.first_name,
                  last_name: json.last_name,
                  email: json.email,
                  zip_code: json.zip_code,
                  living_abroad: Number(json.country_id) !== 139,
                  country_id: json.country_id,
                  country_name: json.country_name,
                  accept_terms: json.accept_terms,
                  password: "",
                  password_repeat: "",
                  hash_id: json.hash_id,
                },
                billing: {
                  billing_name: "",
                  billing_phone: "",
                  billing_country: "",
                  billing_country_name: "",
                  billing_zip: "",
                  billing_city: "",
                  billing_address: "",
                },
                shipping: {
                  shipping_name: "",
                  shipping_country: "",
                  shipping_country_name: "",
                  shipping_zip: "",
                  shipping_city: "",
                  shipping_address: "",
                },
              },
              country_name: "",
            });
            this.getCountryList();
            this.getBillingData(json.id);
            this.getShippingData(json.id);
          } else {
            this.setState({
              authorized: false,
            });
          }
        },
        (error) => {
          // console.log(error);
          this.setState({
            authorized: false,
          });
        }
      );
  }

  getCountryList() {
    fetch(`${AUTH_BASE_URL}/country-list`)
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            countries: json,
          });
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getBillingData(id) {
    fetch(`${AUTH_BASE_URL}/profile/billing/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (Object.keys(json).length > 0) {
            json = json[0];
            this.setState((prevState) => ({
              authorized: true,
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  billing_name: json.billing_name,
                  billing_vat_number: json.billing_vat ? json.billing_vat : "",
                  billing_phone: json.phone_no,
                  billing_country: json.billing_country,
                  billing_country_name: json.billing_country_name,
                  billing_zip: json.billing_zip_code,
                  billing_city: json.billing_city,
                  billing_address: json.billing_address,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                },
              },
            }));
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getShippingData(id) {
    fetch(`${AUTH_BASE_URL}/profile/shipping/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          if (Object.keys(json).length > 0) {
            json = json[0];
            this.setState((prevState) => ({
              authorized: true,
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                },
                shipping: {
                  shipping_name: json.shipping_name,
                  shipping_country: json.shipping_country,
                  shipping_country_name: json.shipping_country_name,
                  shipping_zip: json.shipping_zip_code,
                  shipping_city: json.shipping_city,
                  shipping_address: json.shipping_address,
                },
              },
            }));
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  getZipCode(event) {
    if (event.target.value.length === 4) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState((prevState) => ({
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                  zip_code: `${json[0].zip} ${json[0].city}`,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                },
              },
            }));
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  getBillingZipCode(event) {
    if (
      event.target.value.length === 4 &&
      this.state.registrationForm.billing.billing_country === 139
    ) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState((prevState) => ({
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                  billing_zip: json[0].zip,
                  billing_city: json[0].city,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                },
              },
            }));
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  getShippingZipCode(event) {
    if (
      event.target.value.length === 4 &&
      this.state.registrationForm.shipping.shipping_country === 139
    ) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState((prevState) => ({
              registrationForm: {
                personal: {
                  ...prevState.registrationForm.personal,
                },
                billing: {
                  ...prevState.registrationForm.billing,
                },
                shipping: {
                  ...prevState.registrationForm.shipping,
                  shipping_zip: json[0].zip,
                  shipping_city: json[0].city,
                },
              },
            }));
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  validate() {
    let isValid = true;

    if (
      !this.state.registrationForm.personal.last_name ||
      this.state.registrationForm.personal.last_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_last_name_error: "Helytelen vezetéknév!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_last_name_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.personal.first_name ||
      this.state.registrationForm.personal.first_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_first_name_error: "Helytelen utónév!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_first_name_error: "",
        },
      }));
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.registrationForm.personal.email).toLowerCase())) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_email_error: "Helytelen e-mail cím!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_email_error: "",
        },
      }));
    }

    if (
      this.state.registrationForm.personal.password &&
      this.state.registrationForm.personal.password_repeat &&
      this.state.registrationForm.personal.password.length < 8
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_password_error: "A jelszó hossza legalább 8 karakter!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_password_error: "",
        },
      }));
    }

    if (
      this.state.registrationForm.personal.password &&
      this.state.registrationForm.personal.password_repeat &&
      this.state.registrationForm.personal.password !==
        this.state.registrationForm.personal.password_repeat
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_password_error: "A jelszavak eltérőek!",
        },
      }));
      isValid = false;
    } else if (
      this.state.registrationForm.personal.password &&
      this.state.registrationForm.personal.password_repeat &&
      this.state.registrationForm.personal.password.length >= 8
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_password_error: "",
        },
      }));
    }

    if (
      this.state.registrationForm.personal.zip_code &&
      this.state.registrationForm.personal.zip_code.toString().length < 4
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          personal_zip_code_error: "Helytelen irányítószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState({ personal_zip_code_error: "" });
    }

    // BILLING

    if (
      !this.state.registrationForm.billing.billing_name ||
      this.state.registrationForm.billing.billing_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_name_error: "Helytelen számlázási név!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_name_error: "",
        },
      }));
    }

    const reVat = /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/;
    if (
      this.state.registrationForm.billing.billing_vat_number &&
      !reVat.test(String(this.state.registrationForm.billing.billing_vat_number).toLowerCase())
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_vat_number_error: "Helytelen adószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_vat_number_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_phone ||
      this.state.registrationForm.billing.billing_phone.length !== 11
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_phone_no_error: "Helytelen telefonszám! Formátum: 06301112222",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_phone_no_error: "",
        },
      }));
    }

    const billingCountryName = this.state.registrationForm.billing.billing_country_name;
    if (!this.state.registrationForm.billing.billing_country) {
      const filteredArr = _.filter(this.state.countries, (o) =>
        o.name.toLowerCase().includes(billingCountryName.toLowerCase())
      );
      if (filteredArr.length !== 1) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            billing_country_error: "Helytelen országnév!",
          },
        }));
        isValid = false;
      } else {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            billing_country_error: "",
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_country_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_zip ||
      (this.state.registrationForm.billing.billing_country === 139 &&
        this.state.registrationForm.billing.billing_zip.toString().length !== 4)
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_zip_code_error: "Helytelen irányítószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_zip_code_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_city ||
      this.state.registrationForm.billing.billing_city.toString().length < 2
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_city_error: "Helytelen város!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_city_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.billing.billing_address ||
      this.state.registrationForm.billing.billing_address.length <= 7
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_address_error: "Helytelen cím!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_address_error: "",
        },
      }));
    }

    // SHIPPING
    if (
      !this.state.registrationForm.shipping.shipping_name ||
      this.state.registrationForm.shipping.shipping_name.length < 3
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_name_error: "Helytelen szállítási név!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_name_error: "",
        },
      }));
    }

    const shippingCountryName = this.state.registrationForm.shipping.shipping_country_name;
    if (!this.state.registrationForm.shipping.shipping_country) {
      const filteredArr = _.filter(this.state.countries, (o) =>
        o.name.toLowerCase().includes(shippingCountryName.toLowerCase())
      );
      if (filteredArr.length !== 1) {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            shipping_country_error: "Helytelen országnév!",
          },
        }));
        isValid = false;
      } else {
        this.setState((prevState) => ({
          validation: {
            ...prevState.validation,
            shipping_country_error: "",
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_country_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.shipping.shipping_zip ||
      (this.state.registrationForm.shipping.shipping_country === 139 &&
        this.state.registrationForm.shipping.shipping_zip.toString().length !== 4)
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_zip_code_error: "Helytelen irányítószám!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_zip_code_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.shipping.shipping_city ||
      this.state.registrationForm.shipping.shipping_city.toString().length < 2
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_city_error: "Helytelen város!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          billing_city_error: "",
        },
      }));
    }

    if (
      !this.state.registrationForm.shipping.shipping_address ||
      this.state.registrationForm.shipping.shipping_address.length <= 7
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_address_error: "Helytelen szállítási cím!",
        },
      }));
      isValid = false;
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          shipping_address_error: "",
        },
      }));
    }

    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (this.validate() === true) {
      this.setState({
        is_registration_form_valid: true,
        is_loader_active: true,
        active_shipping_country_search: false,
        active_billing_country_search: false,
      });

      fetch(`${AUTH_BASE_URL}/extended-profile/${this.state.registrationForm.personal.hash_id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(this.state.registrationForm),
      })
        .then((res) => res.json())
        .then(
          (json) => {
            if (json.success) {
              this.setState({
                profile_change_success: true,
                is_loader_active: false,
              });
            } else {
              this.setState({
                profile_change_success: false,
                is_loader_active: false,
              });
            }
          },
          (error) => {
            this.setState({
              profile_change_success: false,
              is_loader_active: false,
            });
          }
        );
    } else {
      this.setState({
        is_registration_form_valid: false,
        is_loader_active: false,
      });
    }
  }

  handleChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
          [id]: isCheckbox ? isChecked : inputValue,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
      },
    }));
  }

  handleCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) =>
      o.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    // console.log(filteredArr);

    this.setState({
      filtered_countries: filteredArr,
      country_name: event.target.value,
    });
  }

  handleBillingChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          [id]: isCheckbox ? isChecked : inputValue,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
      },
    }));
  }

  handleShippingChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === "checkbox";

    this.setState((prevState) => ({
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          [id]: isCheckbox ? isChecked : inputValue,
        },
      },
    }));
  }

  handleBillingCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) =>
      o.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const countryValue = event.target.value;

    this.setState((prevState) => ({
      filtered_countries: filteredArr,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          billing_country_name: countryValue,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
      },
    }));
  }

  handleShippingCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) =>
      o.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    const countryValue = event.target.value;

    this.setState((prevState) => ({
      filtered_countries: filteredArr,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          shipping_country_name: countryValue,
        },
      },
    }));
  }

  modifyCountryValue(event, country_id, country_name) {
    this.setState((prevState) => ({
      country_name,
      active_country_search: false,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
          country_id,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
      },
    }));
  }

  modifyBillingCountryValue(event, country_id, country_name) {
    this.setState((prevState) => ({
      active_billing_country_search: false,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
          billing_country: country_id,
          billing_country_name: country_name,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
        },
      },
    }));
  }

  modifyShippingCountryValue(event, country_id, country_name) {
    this.setState((prevState) => ({
      active_shipping_country_search: false,
      registrationForm: {
        personal: {
          ...prevState.registrationForm.personal,
        },
        billing: {
          ...prevState.registrationForm.billing,
        },
        shipping: {
          ...prevState.registrationForm.shipping,
          shipping_country: country_id,
          shipping_country_name: country_name,
        },
      },
    }));
  }

  activateCountrySearch() {
    this.setState({
      active_country_search: true,
    });
  }

  activateBillingCountrySearch() {
    this.setState({
      active_billing_country_search: true,
    });
  }

  activateShippingCountrySearch() {
    this.setState({
      active_shipping_country_search: true,
    });
  }

  render() {
    return (
      <div className="fixtures-container">
        <Header />
        <Breadcrumb />

        {this.state.authorized && (
          <section className="main-content register-background">
            <div className="container login-container">
              <h2 className="h2">Profil</h2>
              {/* <p></p> */}
              {this.state.profile_change_success === true && (
                <div className="login-success-message">Sikeresen frissítetted a profilodat!</div>
              )}
              {this.state.profile_change_success === false && (
                <div className="login-error-message">
                  Hiba a profilod frissítésekor! Kérjük, próbáld meg később.
                </div>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="last_name">
                      Vezetéknév
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      value={this.state.registrationForm.personal.last_name}
                      onChange={this.handleChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.personal_last_name_error}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="first_name">
                      Keresztnév
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={this.state.registrationForm.personal.first_name}
                      onChange={this.handleChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.personal_first_name_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="email">
                      E-mail cím
                      <span>*</span>
                    </label>
                    <input
                      title="Az e-mail címed módosításához vedd fel velünk a kapcsolatot a dev@honvedfc.hu címen."
                      type="text"
                      className="form-control"
                      id="email"
                      value={this.state.registrationForm.personal.email}
                      onChange={this.handleChange}
                      disabled
                    />
                    <div className="validation_error">
                      {this.state.validation.personal_email_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="password">
                      Jelszó
                      <span>*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      autoComplete="off"
                      value={this.state.registrationForm.personal.password}
                      onChange={this.handleChange}
                    />
                    <PasswordStrengthBar
                      password={this.state.registrationForm.personal.password}
                      className={
                        this.state.registrationForm.personal.password.length === 0 ? "hidden" : ""
                      }
                      scoreWords={["gyenge", "gyenge", "közepes", "jó", "erős"]}
                      shortScoreWord="túl rövid"
                    />
                    {!this.state.registrationForm.personal.password && (
                      <div className="help">
                        <small>
                          (Tipp: hagyd üresen a jelszó mezőket, ha nem akarod megváltoztatni)
                        </small>
                      </div>
                    )}
                    <div className="validation_error">
                      {this.state.validation.base.password_error}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="password_repeat">
                      Jelszó újra
                      <span>*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password_repeat"
                      autoComplete="off"
                      value={this.state.registrationForm.personal.password_repeat}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                {!this.state.registrationForm.personal.living_abroad && (
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="zip_code">Irányítószám</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zip_code"
                        autoComplete="chrome-off"
                        value={this.state.registrationForm.personal.zip_code}
                        onKeyUp={this.getZipCode}
                        onChange={this.handleChange}
                      />
                      <div className="validation_error">
                        {this.state.validation.personal_zip_code_error}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.registrationForm.personal.living_abroad && (
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="country_id">Ország</label>
                      <input
                        type="text"
                        className="form-control"
                        id="country_id"
                        value={this.state.registrationForm.personal.country_name}
                        onChange={this.handleCountryChange}
                        onFocus={this.activateCountrySearch}
                        autoComplete="off"
                      />
                      {this.state.registrationForm.personal.living_abroad &&
                        this.state.country_name.length > 2 &&
                        this.state.active_country_search && (
                          <div className="predictive-country-list">
                            {this.state.filtered_countries.map((item, index) => (
                              <button
                                type="button"
                                key={item.id}
                                onClick={(e) => this.modifyCountryValue(e, item.id, item.name)}
                              >
                                {item.name}
                              </button>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                )}
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="living_abroad"
                    value={this.state.registrationForm.personal.living_abroad}
                    defaultChecked={this.state.registrationForm.personal.country_id !== 139}
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="living_abroad">
                    Külföldön lakom
                  </label>
                </div>

                <h3>Számlázási adatok</h3>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-lg-6">
                    <label htmlFor="billing_name">
                      Név
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billing_name"
                      value={this.state.registrationForm.billing.billing_name}
                      onChange={this.handleBillingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_name_error}
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-lg-6">
                    <label htmlFor="billing_vat_number">
                      Adószám
                      <small>(ÁFÁ-s számla igény esetén)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billing_vat_number"
                      value={this.state.registrationForm.billing.billing_vat_number}
                      onChange={this.handleBillingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_vat_number_error}
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="billing_phone">
                      Telefonszám
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billing_phone"
                      value={this.state.registrationForm.billing.billing_phone}
                      onChange={this.handleBillingChange}
                      placeholder="pl. 06301112222"
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_phone_no_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="country_name">
                      Ország
                      <span>*</span>
                    </label>
                    <input
                      type="hidden"
                      id="country_id"
                      value={this.state.registrationForm.billing.billing_country}
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="country_id"
                      value={this.state.registrationForm.billing.billing_country_name}
                      onChange={this.handleBillingCountryChange}
                      onFocus={this.activateBillingCountrySearch}
                      autoComplete="off"
                    />
                    {this.state.registrationForm.billing.billing_country_name.length > 2 &&
                      this.state.active_billing_country_search && (
                        <div className="predictive-country-list">
                          {this.state.filtered_countries.map((item, index) => (
                            <button
                              type="button"
                              key={item.id}
                              onClick={(e) => this.modifyBillingCountryValue(e, item.id, item.name)}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      )}
                    <div className="validation_error">
                      {this.state.validation.billing_country_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="billing_zip">
                      Irányítószám
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billing_zip"
                      autoComplete="chrome-off"
                      value={this.state.registrationForm.billing.billing_zip}
                      onKeyUp={this.getBillingZipCode}
                      onChange={this.handleBillingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_zip_code_error}
                    </div>
                  </div>
                  <div className="form-group col-md-8">
                    <label htmlFor="billing_city">
                      Város
                      <span>*</span>
                    </label>
                    <input
                      title=""
                      type="text"
                      className="form-control"
                      id="billing_city"
                      readOnly={Number(this.state.registrationForm.billing.billing_country) === 139}
                      value={this.state.registrationForm.billing.billing_city}
                      onChange={this.handleBillingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_city_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="billing_address">
                      Cím
                      <span>*</span>
                    </label>
                    <input
                      title=""
                      type="text"
                      className="form-control"
                      id="billing_address"
                      value={this.state.registrationForm.billing.billing_address}
                      onChange={this.handleBillingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.billing_address_error}
                    </div>
                  </div>
                </div>

                <h3>Szállítási adatok</h3>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="shipping_name">
                      Név
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="shipping_name"
                      value={this.state.registrationForm.shipping.shipping_name}
                      onChange={this.handleShippingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.shipping_name_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="country_name">
                      Ország
                      <span>*</span>
                    </label>
                    <input
                      type="hidden"
                      id="shipping_country_id"
                      value={this.state.registrationForm.shipping.shipping_country}
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="shipping_country_id"
                      value={this.state.registrationForm.shipping.shipping_country_name}
                      onChange={this.handleShippingCountryChange}
                      onFocus={this.activateShippingCountrySearch}
                      autoComplete="off"
                    />
                    {this.state.registrationForm.shipping.shipping_country_name.length > 2 &&
                      this.state.active_shipping_country_search && (
                        <div className="predictive-country-list">
                          {this.state.filtered_countries.map((item, index) => (
                            <button
                              type="button"
                              key={item.id}
                              onClick={(e) =>
                                this.modifyShippingCountryValue(e, item.id, item.name)
                              }
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      )}
                    <div className="validation_error">
                      {this.state.validation.shipping_country_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="shipping_zip">
                      Irányítószám
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="shipping_zip"
                      autoComplete="chrome-off"
                      value={this.state.registrationForm.shipping.shipping_zip}
                      onKeyUp={this.getShippingZipCode}
                      onChange={this.handleShippingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.shipping_zip_code_error}
                    </div>
                  </div>
                  <div className="form-group col-md-8">
                    <label htmlFor="shipping_city">
                      Város
                      <span>*</span>
                    </label>
                    <input
                      title=""
                      type="text"
                      className="form-control"
                      id="shipping_city"
                      readOnly={
                        Number(this.state.registrationForm.shipping.shipping_country) === 139
                      }
                      value={this.state.registrationForm.shipping.shipping_city}
                      onChange={this.handleShippingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.shipping_city_error}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="shipping_address">
                      Cím
                      <span>*</span>
                    </label>
                    <input
                      title=""
                      type="text"
                      className="form-control"
                      id="shipping_address"
                      value={this.state.registrationForm.shipping.shipping_address}
                      onChange={this.handleShippingChange}
                    />
                    <div className="validation_error">
                      {this.state.validation.shipping_address_error}
                    </div>
                  </div>
                </div>

                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="accept_terms"
                    defaultChecked={this.state.registrationForm.personal.accept_terms}
                    disabled
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor="accept_terms"
                    title="Az általános szerződési feltételeket a regisztrációkor elfogadtad. Csak a regisztrációd törlésével vonhatod vissza a hozzájárulást."
                  >
                    <small>
                      Kijelentem, hogy az
                      <Link to="/tartalom/aszf">
                        <u>Általános Szerződési Feltételeket</u>
                      </Link>{" "}
                      és az
                      <Link to="/tartalom/adatvedelem">
                        <u>Adatvédelmi tájékoztatót</u>
                      </Link>{" "}
                      megismertem és elfogadom, s az abban szereplő adatkezelésekhez hozzájárulok.
                    </small>
                  </label>
                </div>
                <div className="text-center submit-container">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.is_loader_active === true}
                  >
                    Módosítom az adataimat
                  </button>
                  {this.state.is_loader_active === true && <Loader />}
                </div>
              </form>
              {this.state.is_registration_form_valid === false && (
                <p className="form-validation-error text-center">
                  Hibásan adtad meg az adatokat, ellenőrizd a pirossal megjelölt mezőket!
                </p>
              )}
            </div>
          </section>
        )}

        {this.state.authorized === false && (
          <section className="main-content">
            <div className="container">
              <div className="access-denied">
                <h2 className="h2">Hiányzó jogosultság</h2>
                <p className="text-center">
                  Nincs jogosultságod az oldal megtekintéséhez, regisztrálj, vagy jelentkezz be az
                  oldalra és ellenőrizd a felhasználói szintedet.
                </p>
              </div>
            </div>
          </section>
        )}

        {this.state.authorized === null && (
          <section className="main-content">
            <div className="container">
              <div className="access-denied">
                <h2 className="h2">Profil</h2>
                <p className="text-center">Egy pillanat, és betöltjük profiladataidat.</p>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </div>
    );
  }
}

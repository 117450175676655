/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { cartState } from 'atoms/atoms';

import Frontpage from './Pages/FrontPage/Frontpage';
import ProductList from './Pages/ProductList/ProductList';
import ProductSheet from './components/product/product-sheet/ProductSheet';
import Register from './components/auth/register/Register';
import Login from './Pages/Login/Login';
import Profile from './components/auth/profile/Profile';
import Checkout from './Pages/Checkout/Checkout';
import ValidateCode from './components/auth/validate-code/ValidateCode';
import StaticContent from './Pages/StaticContent/StaticContent';
import Order from './components/order/Order';
import LostPassword from './components/auth/lost-password/LostPassword';
import PaymentCallback from './components/payment/PaymentCallback';
import AnalyticsService from './services/analytics';
import Search from 'Pages/Search/Search';
import Cart from './Pages/Cart/Cart';

import './App.scss';

function App() {
  const setCartItems = useSetRecoilState(cartState);

  useEffect(() => {
    AnalyticsService.initialize(process.env.REACT_APP_ANALYTICS_ID);
    if (sessionStorage.getItem('basket') !== null) {
      setCartItems(JSON.parse(sessionStorage.getItem('basket')));
    }
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Frontpage />} />
          <Route exact path="/nyitolap" element={<Frontpage />} />
          <Route exact path="/termekek/:id" element={<ProductList />} />
          <Route exact path="/termek/:id" element={<ProductSheet />} />
          <Route exact path="/tartalom/:id" element={<StaticContent />} />
          <Route exact path="/regisztracio" element={<Register />} />
          <Route exact path="/bejelentkezes" element={<Login />} />
          <Route exact path="/bejelentkezes/:id" element={<Login />} />
          <Route exact path="/profil" element={<Profile />} />
          <Route exact path="/kosar" element={<Cart />} />
          <Route exact path="/penztar" element={<Checkout />} />
          <Route exact path="/rendeles" element={<Order />} />
          <Route exact path="/kereses" element={<Search />} />
          <Route exact path="/validate-code/:id" element={<ValidateCode />} />
          <Route exact path="/elfelejtett-jelszo" element={<LostPassword />} />
          <Route exact path="/payment-callback" element={<PaymentCallback />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

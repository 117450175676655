import React, { Component } from 'react';
import './loader.scss';
import { STATIC_SERVER_URL } from '../../../config/config';

export default class LoaderGray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalIsVisible: true,
      marginTop: 0,
    };
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      marginTop: this.props.marginTop,
    });
  }

  toggleLoginModal() {
    this.setState({ loginModalIsVisible: !this.state.loginModalIsVisible });
  }

  render() {
    return (
      <div className="loader">
        <img style={{ marginTop: `${this.state.marginTop}px` }} src={`${STATIC_SERVER_URL}/web/images/shop/loader-gray.gif`} alt="" />
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../shared/header/Header";
import Breadcrumb from "../shared/breadcrumb/Breadcrumb";
import Footer from "../shared/footer/Footer";
import { API_BASE_URL, BARION_API_URL, BARION_POSKEY, PAYMENT_STATUS } from "../../config/config";
import AnalyticsService from "../../services/analytics";

import "./payment.scss";

export default class PaymentCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: null,
      order_number: null,
    };
    this.getParameterByName = this.getParameterByName.bind(this);
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get("paymentId");
    this.setState({
      order_number: sessionStorage.getItem("order_id"),
    });
    this.getPaymentStatus(paymentId);
  }

  getPaymentStatus(paymentId) {
    fetch(
      `${BARION_API_URL}/v2/Payment/GetPaymentState?POSKey=${BARION_POSKEY}&paymentId=${paymentId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then(
        (json) => {
          if (
            json.Status !== "Canceled" &&
            json.Status !== "Succeeded" &&
            json.Status !== "Failed" &&
            json.Status !== "Expired"
          ) {
            setTimeout(() => {
              this.getPaymentStatus(paymentId);
            }, 5000);
          } else {
            this.setState({
              paymentStatus: json.Status,
            });
            this.saveTransactionDetails(json);
            this.closeOrder(sessionStorage.getItem("order_id"), json.Status);
          }
        },
        (error) => {
          console.log(error);
          /* this.setState({
                    order_success: false,
                    order_number: null,
                    is_loader_active: false
                }); */
        }
      );
  }

  closeOrder(id, status) {
    const data = {
      status,
    };
    fetch(`${API_BASE_URL}/order/close/${btoa(id)}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  saveTransactionDetails(data) {
    fetch(`${API_BASE_URL}/transaction/update/${data.PaymentId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /* sendConfirmationEmails() {
        this.setState({
            is_loader_active: true
        });

        fetch( API_BASE_URL + '/order/confirmation/' + btoa(sessionStorage.getItem('order_id')), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: JSON.stringify(sessionStorage.getItem('basket'))
        })
        .then(res => res.json())
        .then(
            (json) => {
                if (json.success) {
                    this.setState({
                        order_success: true,
                        is_loader_active: false
                    });
                } else {
                    this.setState({
                        order_success: false,
                        is_loader_active: false
                    });
                }
            },
            (error) => {
                this.setState({
                    order_success: false,
                    is_loader_active: false
                });
            }
        )
    } */

  getParameterByName(name, url) {
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  render() {
    return (
      <div>
        <Header />
        <Breadcrumb />
        <section className="container payment-container">
          <div className="row">
            <div className="col-12">
              <h2 className="h2">Rendelés</h2>
            </div>
            {this.state.paymentStatus === null && (
              <div className="col-12">
                <p className="text-center">Ellenőrizzük a fizetés sikerességét, kérünk várj.</p>
              </div>
            )}
            {this.state.paymentStatus !== null && (
              <div className="col-12">
                <p className="text-center">{PAYMENT_STATUS[this.state.paymentStatus]}</p>
              </div>
            )}
            {this.state.paymentStatus === "Succeeded" && (
              <div className="col-12">
                <p className="text-center">
                  Köszönjük a rendelésedet, melyet az alábbi azonosítóval véglegesítettünk:
                  <strong>#{this.state.order_number}</strong>
                  .
                  <br />A visszaigazolást regisztrációkor megadott e-mail címedre elküldtük.
                </p>
                <p className="text-center">
                  <Link to="/nyitolap" className="btn btn-primary">
                    tovább a nyitóoldalra
                  </Link>
                </p>
              </div>
            )}
            {(this.state.paymentStatus === "Expired" ||
              this.state.paymentStatus === "Canceled" ||
              this.state.paymentStatus === "Failed") && (
              <div className="col-12">
                <p className="text-center">
                  A fizetés sikertelen volt! Kérjük próbáld meg újra, vagy válassz másik fizetési
                  módot.
                </p>
                <p className="text-center">
                  <Link to="/penztar" className="btn btn-primary">
                    tovább a pénztárhoz
                  </Link>
                </p>
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Interweave } from "interweave";
import { API_BASE_URL, STATIC_SERVER_URL } from "../../../config/config";

import Header from "../../shared/header/Header";
import Footer from "../../shared/footer/Footer";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import SizeSelect from "./size-select/SizeSelect";
import AnalyticsService from "../../../services/analytics";
import Spinner from "components/shared/Spinner/Spinner";

import { useRecoilState } from "recoil";
import { cartState } from "atoms/atoms";

import "./product-sheet.scss";

function ProductSheet(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [productData, setProductData] = useState(null);
  const [showBasketResponse, setBasketResponse] = useState(false);
  const [showSizeError, setShowSizeError] = useState(false);
  const [activePhoto, setActivePhoto] = useState(0);
  const [selectedSize, setSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cartItems, setCartItems] = useRecoilState(cartState);
  const { id } = useParams();
  const maxQuantity = 10;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (sessionStorage.getItem("basket") === null) {
      sessionStorage.setItem("basket", JSON.stringify([]));
    }
    AnalyticsService.sendPageView(window.location.pathname);

    fetch(`${API_BASE_URL}/product/${id}`)
      .then((res) => res.json())
      .then(
        (json) => {
          const photos = [];
          json.photos.forEach((value, index) => {
            photos.push({
              active: index === 0,
              fileName: value,
            });
          });

          setProductData(json);
          setPhotos(photos);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
        }
      );
  }, [id]);

  const selectSize = (data) => {
    setSize(data);
  };

  const handleQuantityChange = (data) => {
    let newQuantity = quantity + data;
    newQuantity = newQuantity === 0 ? 1 : newQuantity > maxQuantity ? maxQuantity : newQuantity;

    setQuantity(newQuantity);
  };

  const selectPhoto = (selectedId) => {
    const modifiedPhotos = photos.map((value, index) => {
      if (index === selectedId) {
        return {
          ...value,
          active: true,
        };
      } else {
        return {
          ...value,
          active: false,
        };
      }
    });

    setPhotos(modifiedPhotos);
    setActivePhoto(selectedId);
  };

  /*
  sendPixelData(data) {
    const contentViewProperties = {
      contentType: "Product",
      currency: "HUF",
      id: data.item_no,
      name: data.name,
      unitPrice: data.price,
      imageUrl: `${STATIC_SERVER_URL}/web/images/shop/products/${data.file_name}`,
    };

    window.bp("track", "contentView", contentViewProperties);
  }*/

  const handleSubmit = (e) => {
    e.preventDefault();

    if (productData.sizes.length > 0 && !selectedSize) {
      setShowSizeError(true);
    } else {
      const cart = [...cartItems];
      cart.push({
        item_no: productData.item_no,
        size: selectedSize,
        quantity: quantity,
        file_name: productData.file_name,
      });

      setCartItems((prev) => [
        ...prev,
        {
          item_no: productData.item_no,
          size: selectedSize,
          quantity: quantity,
          file_name: productData.file_name,
        },
      ]);
      sessionStorage.setItem("basket", JSON.stringify(cart));

      setShowSizeError(false);
      setBasketResponse(true);
    }
  };

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <div>
      <Header />
      <Breadcrumb />
      <section className="product-sheet-container">
        <div className="product-image-container">
          <figure className="product-image">
            {photos.map((item, key) => (
              <div
                key={key}
                className={
                  item.active === true ? "product-image__item active" : "product-image__item"
                }
              >
                <img
                  src={`${STATIC_SERVER_URL}/web/images/shop/products/${item.fileName}`}
                  alt=""
                />
              </div>
            ))}
          </figure>
          <div className="product-thumbs">
            {photos.map((item, key) => (
              <button
                key={key}
                type="button"
                className={
                  Number(activePhoto) === key
                    ? "product-thumbs__item active"
                    : "product-thumbs__item"
                }
                onClick={() => selectPhoto(key)}
              >
                <img
                  src={`${STATIC_SERVER_URL}/web/images/shop/products/${item.fileName}`}
                  alt="mutasd a képet"
                />
              </button>
            ))}
          </div>
          <div className="product-description-container">
            <h3>Ismertető</h3>
            <Interweave tagName="p" content={productData?.description} />
          </div>
        </div>

        <div className="product-details-container">
          <div className="product-details">
            <div className="row">
              <div className="col-12">
                <h2 className="h2">{productData?.name}</h2>
              </div>
              <div className="col-12">
                <p className="product-item-number">
                  Cikkszám:
                  {productData?.item_no}
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <h3 className="h3">Megvennéd most?</h3>
                </div>
                {productData?.sizes.length > 0 && productData?.stock_id === 1 && (
                  <div className="col-12">
                    <div>
                      <p className="size-header-choose">Válassz méretet</p>
                      <p className="size-table">
                        <Link to="/tartalom/merettablazat">mérettáblázat</Link>
                      </p>
                    </div>
                  </div>
                )}
                {productData?.sizes.length > 0 && productData?.stock_id === 1 && (
                  <div className="col-12 size-cube-container">
                    {productData.sizes.map((item, key) => (
                      <SizeSelect
                        key={key}
                        onClick={() => selectSize(item.id)}
                        sizeValue={item.id}
                        sizeName={item.label}
                        isSelected={selectedSize === item.id}
                      />
                    ))}
                  </div>
                )}

                {showSizeError && (
                  <div className="col-12">
                    <p className="error-msg">Hiba! Válaszd ki a termék méretét!</p>
                  </div>
                )}

                {productData?.stock_id === 1 && (
                  <div className="col-12">
                    <label className="select-quantity-label">Mennyiség</label>
                    <div>
                      <button
                        type="button"
                        className="quantity-modifier"
                        onClick={() => handleQuantityChange(-1)}
                      >
                        -
                      </button>
                      <input
                        className="form-control select-quantity-form"
                        id="quantity"
                        type="number"
                        value={quantity}
                        onChange={(event) => {
                          const newValue = Number(event.target.value);
                          const clampedValue = Math.min(Math.max(newValue, 1), maxQuantity); // 0 és 10 közé korlátozza

                          setQuantity(clampedValue);
                        }}
                      />
                      <button
                        type="button"
                        className="quantity-modifier"
                        onClick={() => handleQuantityChange(1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {productData?.stock_id === 1 && (
                  <div className="col-12">
                    <p className="total-price">
                      Összesen:
                      <span className="big-price">
                        {(productData.price * quantity).toLocaleString()} Ft
                      </span>
                    </p>
                  </div>
                )}
                {productData?.stock_id === 1 && (
                  <>
                    <div className="col-12">
                      <button type="submit" className="btn-basket">
                        <img
                          src={`${STATIC_SERVER_URL}/web/images/shop/cart-icon.svg`}
                          alt="Kosárba"
                        />
                        kosárba
                      </button>
                    </div>
                    <div className="col-12">
                      <p className="shipping-details">
                        A termékkel kapcsolatos igényeket a rendelés leadásakor, a pénztár oldal
                        megjegyzés mezőjében várjuk. (mezfeliratozás, telefon típusa tokok esetében)
                      </p>
                    </div>
                  </>
                )}
                {productData?.stock_id !== 1 && (
                  <div className="col-12">
                    <p className="out-of-stock">A termék jelenleg nem rendelhető.</p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className={showBasketResponse ? "basket-response show" : "basket-response"}>
        <div>
          <p>
            <strong>A kiválasztott terméket beraktuk a kosárba.</strong>
          </p>
        </div>
        <div>
          <Link to="/nyitolap" className="red-btn">
            tovább vásárolok
          </Link>
          <Link to="/penztar" className="gray-btn">
            pénztárhoz
          </Link>
          <Link to="/kosar" className="gray-btn">
            megnézem a kosarat
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ProductSheet;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import Hero from "../../components/frontpage/hero/Hero";
import PromoboxSecondary from "../../components/frontpage/promobox-secondary/PromoboxSecondary";
import AnalyticsService from "../../services/analytics";
import PopularProducts from "../../components/frontpage/popular-products/PopularProducts";

function Frontpage() {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const enableBarion = Number(params.get("enableBarion"));

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    if (enableBarion === 1) sessionStorage.setItem("enableBarion", "1");
  }, [enableBarion]);

  return (
    <div>
      <Header />
      <Hero />
      <PromoboxSecondary />
      <PopularProducts />
      <Footer />
    </div>
  );
}

export default Frontpage;

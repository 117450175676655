import React, { Component } from 'react';
import Breadcrumb from '../../shared/breadcrumb/Breadcrumb';
import Footer from '../../shared/footer/Footer';
import './register.scss';
import { AUTH_BASE_URL } from '../../../config/config';
import PasswordStrengthBar from 'react-password-strength-bar';
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from "react-google-login";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Header from '../../shared/header/Header';
import Loader from '../../shared/loader/Loader';
import AnalyticsService from '../../../services/analytics';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  date_of_birth: '',
  password: '',
  password_repeat: '',
  zip_code: '',
  living_abroad: false,
  country_id: 0,
  country_name: '',
  accept_terms: false,
  last_name_error: '',
  first_name_error: '',
  email_error: '',
  password_error: '',
  zip_code_error: '',
  accept_terms_error: '',
  country_error: '',
  countries: [],
  filtered_countries: [],
  active_country_search: false,
  social_login_success: false,
  is_loader_active: false,
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.getZipCode = this.getZipCode.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.modifyCountryValue = this.modifyCountryValue.bind(this);
    this.activateCountrySearch = this.activateCountrySearch.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.getCountryList();
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    /* window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: "484814130569-fbfd4v7abqsumklpfllgdi4c31u52mtd"
      }).then(() => {
        window.gapi.signin2.render('g-signin2', {
          'scope': 'https://www.googleapis.com/auth/plus.login',
          'width': 200,
          'height': 50,
          'longtitle': true,
          'theme': 'light',
          'onsuccess': this.onSignIn
        })
      })
    }) */
  }

  componentDidUpdate() {
    AnalyticsService.sendPageView(window.location.pathname);
  }

  getCountryList() {
    fetch(`${AUTH_BASE_URL}/country-list`)
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            countries: json,
          });
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  validate() {
    let isValid = true;

    if (this.state.last_name.length < 3) {
      this.setState({ last_name_error: 'Helytelen vezetéknév!' });
      isValid = false;
    } else {
      this.setState({ last_name_error: '' });
    }

    if (this.state.first_name.length < 3) {
      this.setState({ first_name_error: 'Helytelen utónév!' });
      isValid = false;
    } else {
      this.setState({ first_name_error: '' });
    }

    if (Number(this.state.date_of_birth.length) !== 4) {
      this.setState({ first_name_error: 'Helytelen születési év!' });
      isValid = false;
    } else {
      this.setState({ first_name_error: '' });
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.email).toLowerCase())) {
      this.setState({ email_error: 'Helytelen e-mail cím!' });
      isValid = false;
    } else {
      this.setState({ email_error: '' });
    }

    if (this.state.password.length < 8) {
      this.setState({ password_error: 'A jelszó hossza legalább 8 karakter!' });
      isValid = false;
    } else {
      this.setState({ password_error: '' });
    }

    if (this.state.password !== this.state.password_repeat) {
      this.setState({ password_error: 'A jelszavak eltérőek!' });
      isValid = false;
    } else if (this.state.password.length >= 8) {
      this.setState({ password_error: '' });
    }

    if (this.state.zip_code.toString().length < 4 && !this.state.living_abroad) {
      this.setState({ zip_code_error: 'Helytelen irányítószám!' });
      isValid = false;
    } else {
      this.setState({ zip_code_error: '' });
    }

    if (this.state.country_name.toString().length < 4 && this.state.living_abroad) {
      this.setState({ country_error: 'Helytelen országnév!' });
      isValid = false;
    } else {
      this.setState({ country_error: '' });
    }

    if (this.state.accept_terms !== true) {
      this.setState({ accept_terms_error: 'A feltételek elfogadása kötelező!' });
      isValid = false;
    } else {
      this.setState({ accept_terms_error: '' });
    }

    return isValid;
  }

  handleSubmit(event) {
    this.setState({
      is_loader_active: true,
    });

    event.preventDefault();
    if (this.validate() === true) {
      this.saveRegisterData();
    } else {
      this.setState({
        is_loader_active: false,
      });
    }
  }

  saveRegisterData() {
    fetch(`${AUTH_BASE_URL}/register`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
          if (json.success === true) {
            // this.props.history.push(`/validate-code/${json.sign}`);
            window.location.href = `/validate-code/${json.sign}`;
            this.setState(initialState);
          } else {
            this.setState({
              email_error: json.reason,
              is_loader_active: false,
            });
          }
        },
        (error) => {
          this.setState({
            is_loader_active: false,
          });
        },
      );
  }

  handleChange(event) {
    const isCheckbox = event.target.type === 'checkbox';

    this.setState({
      [event.target.id]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });
  }

  handleEmailChange(event) {
    fetch(`${AUTH_BASE_URL}/check-email/${event.target.value}`)
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
          if (json.success === true) {
            this.setState({
              email_error: '',
            });
          } else {
            this.setState({
              email_error: json.reason,
            });
          }
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  getZipCode(event) {
    if (event.target.value.length === 4) {
      fetch(`${AUTH_BASE_URL}/zip-list/${event.target.value}`)
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState({
              zip_code: `${json[0].zip} ${json[0].city}`,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          },
        );
    }
  }

  handleGoogleClick(event) {
    fetch(`${AUTH_BASE_URL}/login/google`)
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  handleCountryChange(event) {
    const filteredArr = _.filter(this.state.countries, (o) => o.name.toLowerCase().includes(event.target.value.toLowerCase()));

    this.setState({
      filtered_countries: filteredArr,
      country_name: event.target.value,
    });

    if (filteredArr.length === 1) {
      this.setState({
        country_id: filteredArr[0].id,
        country_error: '',
        active_country_search: true,
      });
    } else if (filteredArr.length === 0) {
      this.setState({
        country_error: 'Nem létező országot adtál meg!',
        active_country_search: false,
      });
    } else if (filteredArr.length > 1) {
      this.setState({
        country_error: 'Nem létező országot adtál meg!',
        active_country_search: true,
      });
    }
  }

  modifyCountryValue(event, country_id, country_name) {
    this.setState({
      country_id,
      country_name,
      active_country_search: false,
    });
  }

  activateCountrySearch() {
    this.setState({
      active_country_search: true,
    });
  }

  responseFacebook(response) {
    // console.log(response);
    const userDetails = {
      first_name: response.name.substring(0, response.name.indexOf(' ')),
      last_name: response.name.substring(response.name.indexOf(' ') + 1, response.name.length),
      email: response.email,
      provider: 'facebook',
    };

    fetch(`${AUTH_BASE_URL}/login/social/facebook`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userDetails),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          // console.log(json);
          if (json.token) {
            sessionStorage.setItem('token', json.token);
            this.setState({ social_login_success: true });
          } else {
            // console.log('Hiba!');
          }
        },
        (error) => {
          // console.log(error);
        },
      );
  }

  redirectAfterSocialLogin() {
    if (this.state.social_login_success) {
      this.props.history.push('/legfrissebb');
    }
  }

  render() {
    /* const responseGoogle = (response) => {
            let userDetails = {
                first_name: response.getBasicProfile().getGivenName(),
                last_name: response.getBasicProfile().getFamilyName(),
                email: response.getBasicProfile().getEmail(),
                provider: 'google'
            };

            fetch(AUTH_BASE_URL + '/login/social/google', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userDetails)
            })
                .then(res => res.json())
                .then(
                    (json) => {
                        //console.log(json);
                        if (json.token) {
                            sessionStorage.setItem('token', json.token);
                            this.setState({social_login_success: true});
                        } else {
                            //console.log('Hiba!');
                        }
                    },
                    (error) => {
                        //console.log(error);
                    }
                );
        }; */

    return (
      <div className="fixtures-container">
        {this.redirectAfterSocialLogin()}
        <Header />
        <Breadcrumb />

        <section className="main-content register-background">
          <div className="container register-container">
            <h2 className="h2">Regisztráció</h2>
            <p className="register-description">Regisztrálj és két perc múlva már rendelhetsz a Honvéd Shopból!</p>
            <p className="register-description">Felhasználói fiókodat aktiválnod kell, mielőtt bejelentkeznél az oldalra, minden ehhez kapcsolódó tovább információt visszaigazoló e-mail-ünk tartalmaz, melyet a rendszer automatikusan kiküld az általad megadott címre.</p>
            {/* <div className="social-container">
              <div>
                <FacebookLogin
                  appId="479443535523657"
                  autoLoad={true}
                  fields="name,email,picture"
                  cssClass="btn-custom-facebook"
                  callback={this.responseFacebook}
                />
              </div>
              <div>
                <GoogleLogin
                  clientId="484814130569-fbfd4v7abqsumklpfllgdi4c31u52mtd.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  className={'g-signin2'}
                />
              </div>
            </div>*
            <div className="separator">
              <p>vagy</p>
              <span></span>
            </div> */}
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="last_name">
                    Vezetéknév
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                  />
                  <div className="validation_error">{this.state.last_name_error}</div>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="first_name">
                    Keresztnév
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChange}
                  />
                  <div className="validation_error">{this.state.first_name_error}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="email">
                    E-mail cím
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.handleEmailChange}
                  />
                  <div className="validation_error">{this.state.email_error}</div>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="first_name">
                    Születési év
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="date_of_birth"
                    value={this.state.date_of_birth}
                    onChange={this.handleChange}
                  />
                  <div className="validation_error">{this.state.date_of_birth_error}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="password">
                    Jelszó
                    <span>*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <PasswordStrengthBar
                    password={this.state.password}
                    className={(this.state.password.length === 0) ? 'hidden' : ''}
                    scoreWords={['gyenge', 'gyenge', 'közepes', 'jó', 'erős']}
                    shortScoreWord="túl rövid"
                  />
                  <div className="validation_error">{this.state.password_error}</div>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="password_repeat">
                    Jelszó újra
                    <span>*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_repeat"
                    autoComplete="off"
                    value={this.state.password_repeat}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {!this.state.living_abroad && (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="zip_code">
                      Irányítószám
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="zip_code"
                      autoComplete="chrome-off"
                      value={this.state.zip_code}
                      onKeyUp={this.getZipCode}
                      onChange={this.handleChange}
                    />
                    <div className="validation_error">{this.state.zip_code_error}</div>
                  </div>
                </div>
              )}
              {this.state.living_abroad && (
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="country_id">
                      Ország
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="country_id"
                      value={this.state.country_name}
                      onChange={this.handleCountryChange}
                      onFocus={this.activateCountrySearch}
                    />
                    <div className="validation_error">{this.state.country_error}</div>
                    {this.state.living_abroad && (this.state.country_name.length > 2) && this.state.active_country_search && (
                      <div className="predictive-country-list">
                        {this.state.filtered_countries.map((item, index) => (
                          <button type="button" key={item.id} onClick={(e) => this.modifyCountryValue(e, item.id, item.name)}>{item.name}</button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="living_abroad"
                  value={this.state.living_abroad}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" htmlFor="living_abroad"><small>Külföldön lakom</small></label>
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="accept_terms"
                  value={this.state.accept_terms}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" htmlFor="accept_terms">
                  <small>
                    Kijelentem, hogy az
                    <Link to="/tartalom/aszf">
                      <u>
                        Általános
                        Szerződési Feltételeket
                      </u>
                    </Link>
                    {' '}
                    és az
                    <Link to="/tartalom/adatvedelem"><u>Adatvédelmi tájékoztatót</u></Link>
                    {' '}
                    megismertem és elfogadom, s
                    abban szereplő adatkezelésekhez hozzájárulok.
                  </small>
                </label>
                <div className="validation_error">{this.state.accept_terms_error}</div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary" disabled={(this.state.is_loader_active === true)}>Regisztrálok</button>
                {this.state.is_loader_active === true && (
                  <Loader />
                )}
              </div>
            </form>
            <div className="text-center existing-account">
              <p>
                Már van tagságod?
                <Link to="/bejelentkezes"><u>Jelentkezz be itt!</u></Link>
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import './parcel-points.scss';
import { API_BASE_URL } from 'config/config';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 47.4730517,
  lng: 19.1019954,
};

const ParcelPoints = (props) => {
  const [parcelPointsData, setParcelPointsData] = useState(null);
  const [, setMap] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem('parcelPointsData')) {
      setParcelPointsData(JSON.parse(sessionStorage.getItem('parcelPointsData')));
    } else {
      fetch(`${API_BASE_URL}/gls-parcel-points`)
        .then((res) => res.json())
        .then(
          (json) => {
            setParcelPointsData(json.items);
            sessionStorage.setItem('parcelPointsData', JSON.stringify(json.items));
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setCenter(center);
    map.setZoom(11);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  const handleParcelPointSelection = (id) => {
    props.onSelected(id);
    handleCloseInfoWindow();
  };

  return (isLoaded && parcelPointsData && parcelPointsData.length) ? (
    <>
      <div style={{ visibility: 'hidden', height: 0 }}>{parcelPointsData.length}</div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer gridSize={60}>
          {(clusterer) => {
            return parcelPointsData.map((marker, index) => (
              <Marker key={index} position={{ lat: marker.location[0], lng: marker.location[1] }} onClick={() => handleMarkerClick(marker)} clusterer={clusterer} />
            ))
          }
          }
        </MarkerClusterer>
        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.location[0], lng: selectedMarker.location[1] }}
            onCloseClick={handleCloseInfoWindow}
          >
            <>
              <h4>{selectedMarker.name}</h4>
              <p>{selectedMarker.type === 'parcel-locker' ? 'Csomagautomata' : 'Csomagpont'}</p>
              {selectedMarker.description && <p>{selectedMarker.description}</p>}
              <p>Cím: {selectedMarker.contact.postalCode} {selectedMarker.contact.city}, {selectedMarker.contact.address}</p>
              <p>
                <button className="btn btn-primary btn-small" onClick={(e) => handleParcelPointSelection(selectedMarker.externalId)}>Kiválaszt</button>
              </p>
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) : <div>Betöltés</div>
}

export default ParcelPoints;

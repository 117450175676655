import React from "react";
import { Link } from "react-router-dom";
import { STATIC_SERVER_URL } from "../../../config/config";

import "./productlist-item.scss";
import AsyncImage from "components/shared/AsyncImage/AsyncImage";

function ProductListItem({ id, item_no, name, price }) {
  return (
    <div className="product-card col-6 col-md-4 col-lg-3">
      <Link to={`/termek/${id}`}>
        <figure>
          <AsyncImage
            src={`${STATIC_SERVER_URL}/web/images/shop/products/${item_no}_001.png`}
            alt={name}
          />
          <div className="line" />
        </figure>
        <h3>{name}</h3>
        <div className="text-center">
          <p className="price">{price.toLocaleString()} Ft</p>
        </div>
      </Link>
    </div>
  );
}

export default ProductListItem;
